import * as React from 'react';
import { Button } from '@mui/material';
import CoveredAppBar from './CoveredAppBar';
import { Avatar } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@mui/material';
import { Card, CardContent, CardActions } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import { Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TextField } from '@mui/material';
import UserContext from '../UserContext';
import { CreateOrEditPractice } from './CreateOrEditPractice';

export default function Search() {
    const [practices, setPractices] = React.useState([]);
    const [practiceDataLoaded, setPracticeDataLoaded] = React.useState(false);
    const [selectedPractice, setSelectedPractice] = React.useState('');

    const [docResults, setDocResults] = React.useState([]);
    const [clinicResults, setClinicResults] = React.useState([]);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const [creditPurchaseDialogOpen, setCreditPurchaseDialogOpen] = React.useState(false);

    const [searchSubmitted, setSearchSubmitted] = React.useState(false);
    const [searchMode, setSearchMode] = React.useState('search-relief-doctors');
    const [clinicSearchAddress, setClinicSearchAddress] = React.useState('');
    const [clinicSearchState, setClinicSearchState] = React.useState(null);
    const [maxTravelDistance, setMaxTravelDistance] = React.useState(20);

    const [sentConnectionRequests, setSentConnectionRequests] = React.useState([]);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [failureSnackbarOpen, setFailureSnackbarOpen] = React.useState(false);
    const [searchErrorText, setSearchErrorText] = React.useState(null);
    // const [connectionRequests, setConnectionRequests] = React.useState([]);
    const [searching, setSearching] = React.useState(false);

    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    var { user, setUser } = React.useContext(UserContext);
    const auth = {auth: {token: token, email: user?.email}};

    React.useEffect(() => {
      if (user && !practiceDataLoaded) {
        refreshPracticeData();
        loadConnectionRequests();
      }

      var clinicSearchAddressInput = document.getElementById('clinic-search-location');
      var options = {
        componentRestrictions: { country: 'us' },
        fields: [
          'formatted_address',
          'address_components',
        ]
      }

      if (window.google) {
        var clinicSearchAddressAutocomplete = new window.google.maps.places.Autocomplete(clinicSearchAddressInput, options)
        clinicSearchAddressAutocomplete.addListener('place_changed', () => {
          const location = clinicSearchAddressAutocomplete.getPlace();
          setClinicSearchAddress(location.formatted_address);

          const state = location.address_components.filter(c => c.types.includes('administrative_area_level_1'))[0].short_name
          setClinicSearchState(state)
        });
      }
    })

    const loadConnectionRequests = () => {
      fetch(new URL(`api/connection-requests/?requester_id=${user.id}&requestee_id=${user.id}`, process.env.REACT_APP_BASE_URL), {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.detail) {
            console.log('oops :(')
            console.log(responseJson.detail)
          } else {
            // setConnectionRequests(responseJson);
          }
        })
    }

    const refreshPracticeData = () => {
      fetch(new URL(`api/practices/?owner_id=${user.id}`, process.env.REACT_APP_BASE_URL), {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      })
      .then(response => {
        if (response.status === 401) {
          console.log('token expired - redirecting')
          localStorage.setItem("token", null)
          localStorage.setItem("practice", null)
          localStorage.setItem("userId", null)
          localStorage.setItem("userEmail", null)
          setUser(null)
          navigate('/sign-in')
        }
        return response.json()
      })
      .then(data => {
        if (data.detail) {
          console.log('oops')
          console.log(data.detail)
        } else {
          data.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p2.name > p1.name) ? -1 : 0)
          setPractices(data)
          if (data.length) {
            setSelectedPractice(data[0])
          }
          setPracticeDataLoaded(true)
        }
      })
    }

    const search = (location, state) => {
        setSearching(true)
        const address = encodeURIComponent(location)

        let querySlug = 'api/'
        if (searchMode === 'search-relief-doctors') {
          querySlug += `users/?available_location=${address}`
          fetch(new URL(querySlug, process.env.REACT_APP_BASE_URL), {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
              .then(response => response.json())
              .then(responseJson => {
                setSearching(false)
                if (responseJson.detail) {
                  setSearchErrorText(responseJson.detail)
                } else {
                  setSearchErrorText('')
                  // Filter out the current user and the test account
                  setDocResults(responseJson.filter(userResponse => ![user.id, '60f4785f-f07e-4a3f-ba5c-5fa8e9f8b0f9'].includes(userResponse.id)))
                  setSearchSubmitted(true)
                }
              })
        }

        if (searchMode === 'search-clinics') {
          querySlug += `practices-by-location/?searcher_id=${user.id}&location=${address}&max_distance=${maxTravelDistance}&state=${clinicSearchState}`
          fetch(new URL(querySlug, process.env.REACT_APP_BASE_URL), {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
              .then(response => response.json())
              .then(responseJson => {
                setSearching(false)
                if (responseJson.detail) {
                  setSearchErrorText(responseJson.detail)
                } else {
                  setSearchErrorText('')
                  // Filter out the current user and the test account
                  setClinicResults(responseJson.filter(clinicResponse => ![user.id, '60f4785f-f07e-4a3f-ba5c-5fa8e9f8b0f9'].includes(clinicResponse.owner.id)))
                  setSearchSubmitted(true)
                }
              })
        }
    }

    const sendBookingRequest = (requestedDoc) => {
        const request = {
          datetime: new Date(),
          status: 'pending',
          cost: requestedDoc.daily_rate,
          requester_id: user.id,
          requestee_id: requestedDoc.id,
          practice_id: selectedPractice.id,
          travel_distance: requestedDoc.travel_distance,

          // This is always set to true for now to basically make connections free
          preexisting_connection: true,
        }
        fetch(new URL(`api/connection-requests/`, process.env.REACT_APP_BASE_URL), {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
              ...auth,
              request: request
            })
        })
        .then((data) => {
          if (data.ok) {
            setSuccessSnackbarOpen(true)

            // Add the user id to pending requests list of requestee so we immediately see 'connection pending'
            if (searchMode === 'search-relief-doctors') {
              docResults.filter(result => result.id === requestedDoc.id)[0].pending_requests_from.push(user.id)
            }
            return data.json()
          } else {
            setFailureSnackbarOpen(true)
          }
        })
        .then(newId => {
          loadConnectionRequests();
          setSentConnectionRequests([...sentConnectionRequests, requestedDoc.id])
        })
    };

    const sendRequestOrPromptToPurchaseCredits = (userToConnectTo) => {
      // const numOutgoingPendingRequests = requestedWork.filter(rfw => (rfw.status === 'pending' && rfw.requester_id === user.id)).length;
      // if (user.connection_credits && user.connection_credits > numOutgoingPendingRequests) {
      if (true) {
        sendBookingRequest(userToConnectTo);
      } else {
        setCreditPurchaseDialogOpen(true);
      }
    }

    const availabilityTypes = (docResult) => {
      let result = []
      if (docResult.available_for_relief_dates_flexible) {
        result.push('Open/flexible')
      }

      if (docResult.estimated_relief_days_of_week) {
        let weeklyRecurringDays = []

        if (docResult.estimated_relief_days_of_week.includes('M')) {
          weeklyRecurringDays.push('Monday')
        }
        if (docResult.estimated_relief_days_of_week.includes('T')) {
          weeklyRecurringDays.push('Tuesday')
        }
        if (docResult.estimated_relief_days_of_week.includes('W')) {
          weeklyRecurringDays.push('Wednesday')
        }
        if (docResult.estimated_relief_days_of_week.includes('R')) {
          weeklyRecurringDays.push('Thursday')
        }
        if (docResult.estimated_relief_days_of_week.includes('F')) {
          weeklyRecurringDays.push('Friday')
        }
        if (docResult.estimated_relief_days_of_week.includes('A')) {
          weeklyRecurringDays.push('Saturday')
        }
        if (docResult.estimated_relief_days_of_week.includes('U')) {
          weeklyRecurringDays.push('Sunday')
        }

        let weeklyRecurringText = docResult.estimated_relief_days_per_month + ' days per month on ' + weeklyRecurringDays.join(', ');
        if (!!docResult.recurring_availability_start_date && docResult.recurring_availability_start_date !== 'None') {
          weeklyRecurringText += ` starting ${docResult.recurring_availability_start_date}`
        }
        if (!!docResult.recurring_availability_end_date && docResult.recurring_availability_end_date !== 'None') {
          weeklyRecurringText += ` ending ${docResult.recurring_availability_end_date}`
        }

        result.push(weeklyRecurringText)
      }

      const futureSpecificDateAvailability = docResult.availability.filter(date => new Date(date) >= new Date()).sort();

      if (futureSpecificDateAvailability.length) {
        result.push('Specific dates: ' + futureSpecificDateAvailability.join(', '))
      }

      if (!result.length) {
        result.push('Unknown')
      }

      return result
    }

    const renderedDocList = docResults.map((result) =>
      <div onClick={() => window.open(`/user/${result.id}`, '_blank')} key={result.id} style={{cursor: 'pointer'}}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '30px'}}>
          {/* { !result?.connections.includes(user.id) && <Avatar alt={result.name} style={{marginLeft: '20px', marginRight: '20px', filter: 'blur(12px)', height: 100, width: 100}} src={result.profile_photo_url}/> }
          { result?.connections.includes(user.id) &&<Avatar alt={result.name} style={{marginLeft: '20px', marginRight: '20px', height: 100, width: 100}} src={result.profile_photo_url}/> } */}
          <Avatar alt={result.name} style={{marginRight: '20px', height: 75, width: 75}} src={result.profile_photo_url}>{!result.profile_photo_url && result.name[0]}</Avatar>
          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <div>
              {/* { !result?.connections.includes(user.id) && <Typography variant="h6" style={{display: 'inline', color: 'transparent', textShadow: '0 0 18px #000'}}>{result.name}</Typography> }
              { result?.connections.includes(user.id) && <Typography variant="h6" style={{display: 'inline'}}>{result.name}</Typography> }
              <Typography variant="h6" style={{display: 'inline'}}>, O.D.</Typography> */}
              <Typography variant="h6" style={{display: 'inline'}}>{result.name}, O.D.</Typography>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '5px'}}>
              { result.license_number.includes('T') && <Chip icon={<CheckIcon />} label='Therapeutic' variant='outlined' color='success' size='small'/> }
              { result.license_number.includes('G') && <Chip icon={<CheckIcon />} label='Glaucoma' style={{marginLeft: '10px'}} variant='outlined' color='primary' size='small'/> }
            </div>
            { result.years_of_experience && <Typography style={{display: 'flex'}}>{result.years_of_experience} years of experience</Typography> }
            { result.max_patients_per_hour && <Typography style={{display: 'flex'}}>{result.max_patients_per_hour} max patients/hour</Typography> }
            <Typography style={{display: 'flex'}}>{result.travel_distance.toFixed()} miles away (will travel {result.max_travel_distance_miles} miles)</Typography>
            <Typography style={{display: 'flex'}}>Availability (last updated {new Date(result.availability_last_updated).toLocaleDateString('en-us')}): </Typography>
            {availabilityTypes(result).map(availabilityType => (
              <li key={availabilityType} style={{paddingLeft: '10px'}}>{ availabilityType }</li>
            ))}
            {/* <Typography variant="body2" style={{display: 'flex'}}>${result.daily_rate} typical weekday rate</Typography> */}
          </div>
          { (!result?.pending_requests_from.includes(user.id) && !result?.connections.includes(user.id)) && <Button onClick={() => {sendRequestOrPromptToPurchaseCredits(result)}} variant='outlined' sx={{height: '40px'}}>Connect</Button>}
          { (!result?.pending_requests_from.includes(user.id) && result?.connections.includes(user.id)) && 'Already connected'}
          { result?.pending_requests_from.includes(user.id) && 'Connection pending'}
        </div>
        <Divider />
      </div>
    )

    const renderedClinicList = clinicResults.map((result) =>
      <div key={result.id} style={{cursor: 'pointer'}}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '30px'}}>
          <div style={{display: 'flex', flexGrow: 1}} onClick={() => window.open(`/user/${result.owner.id}`, '_blank')}>
            <Avatar alt={result.name} style={{marginLeft: '20px', marginRight: '20px', height: 75, width: 75}} src={result.owner.profile_photo_url}>{!result.owner.profile_photo_url && result.name[0]}</Avatar>
            <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
              <div>
                <Typography style={{fontSize: 14}} color='text.secondary'>{result.owner.name}</Typography>
                <Typography variant="h6" style={{display: 'inline'}}>{result.name}</Typography>
                <Typography>{result.travel_distance.toFixed(1)} miles away</Typography>
                <Typography>{result.address}</Typography>
              </div>
              {/* <div style={{display: 'flex', flexDirection: 'row', marginTop: '5px'}}>
                { result.license_number.includes('T') && <Chip icon={<CheckIcon />} label='Therapeutic' variant='outlined' color='success' size='small'/> }
                { result.license_number.includes('G') && <Chip icon={<CheckIcon />} label='Glaucoma' style={{marginLeft: '10px'}} variant='outlined' color='primary' size='small'/> }
              </div>
              { result.years_of_experience && <Typography style={{display: 'flex'}}>{result.years_of_experience} years of experience</Typography> }
              { result.max_patients_per_hour && <Typography style={{display: 'flex'}}>{result.max_patients_per_hour} max patients/hour</Typography> }
              <Typography style={{display: 'flex'}}>{result.travel_distance.toFixed()} miles away (will travel {result.max_travel_distance_miles} miles)</Typography>
              <Typography style={{display: 'flex'}}>Availability (last updated {new Date(result.availability_last_updated).toLocaleDateString('en-us')}): </Typography>
              {availabilityTypes(result).map(availabilityType => (
                <li key={availabilityType} style={{paddingLeft: '10px'}}>{ availabilityType }</li>
              ))} */}
            </div>

          </div>
          { !result.owner.connected && !result.owner.connection_pending && !sentConnectionRequests.includes(result.owner.id) && <Button onClick={() => {sendRequestOrPromptToPurchaseCredits(result.owner)}} variant='outlined' sx={{height: '40px'}}>Connect</Button>}
          { result.owner.connected && 'Already connected'}
          { (result.owner.connection_pending || sentConnectionRequests.includes(result.owner.id)) && 'Connection pending'}
        </div>
        <Divider />
      </div>
    )

    return (
        <div>
            <CoveredAppBar />
            { !searchSubmitted && (
              <Card style={{maxWidth: '550px', margin: 'auto', display: 'flex', flexDirection: 'column', padding: '20px', marginTop: '20px'}}>
                <CardContent sx={{margin: 'auto'}}>
                  <div>
                    <FormControl>
                      <div>
                        <RadioGroup
                          aria-labelledby="search-radio-buttons-group-label"
                          defaultValue="search-relief-doctors"
                          name="radio-buttons-group"
                          value={searchMode}
                          onChange={(e) => setSearchMode(e.target.value)}
                        >
                          <FormControlLabel value="search-relief-doctors" control={<Radio />} label="Search relief doctors" />
                          <div>
                            <FormControl sx={{margin: '0 10px 20px 10px'}}>
                              <InputLabel id="select-location-label">Select Clinic *</InputLabel>
                              <Select required value={selectedPractice} sx={{width: '250px'}} labelId="select-location-label" id="select-location" label="Select Clinic *" onChange={(e) => setSelectedPractice(e.target.value)}>
                                {practices.map(practice => (
                                  <MenuItem value={practice} key={practice.id}>{practice.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Typography display='inline' style={{margin: '10px', lineHeight: '50px'}}>- or -</Typography>
                            <Button onClick={() => navigate('/user-profile/my-clinics')} variant='outlined' style={{marginLeft: '10px'}} size='large'>Add a clinic</Button>
                          </div>
                          <FormControlLabel value="search-clinics" control={<Radio />} label="Search clinics" />
                          <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <TextField id="clinic-search-location" label="Location *" variant="outlined" style={{width: '50%'}} />
                            <TextField
                              required
                              id="clinic-search-max-distance"
                              label="Max Distance (miles)"
                              variant="outlined"
                              type='number'
                              style={{width: '40%'}}
                              value={maxTravelDistance}
                              onChange={(e) => setMaxTravelDistance(e.target.value)}
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </FormControl>
                  </div>
                  <br/>
                  {/* <div style={{display: 'none'}}>
                    <Typography gutterBottom>Date(s)</Typography>
                    <ToggleButtonGroup
                      value={searchDateOption}
                      exclusive
                      onChange={(_, newVal) => {newVal && setSearchDateOption(newVal)}}
                      aria-label="text alignment"
                      sx={{marginLeft: '10px', marginBottom: '10px'}}
                    >
                      <ToggleButton value="singledate" aria-label="single date">
                        <Typography>Date</Typography>
                      </ToggleButton>
                      <ToggleButton value="anytime" aria-label="any time">
                        <Typography>Any Time/Flexible</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <br />
                  {searchDateOption !== 'anytime' && <DatePicker value={date} onChange={setDate} style={{marginLeft: '10px', height: '50px', fontSize: '16px', borderRadius: '4px'}}/>}
                  </div> */}
                  {searchErrorText && (
                    <div style={{color: 'red', margin: 'auto', marginBottom: '10px'}}>
                      {`There was an error: ${searchErrorText}. `}
                      <br />
                      {`Please email cfh@getcovered.health for support if this continues.`}
                    </div>
                  )}
                </CardContent>
                <CardActions>
                  <Button variant='contained' size='large' onClick={() => {searchMode === 'search-relief-doctors' ? setConfirmationDialogOpen(true) : search(clinicSearchAddress, clinicSearchState)}} disabled={searching || (!selectedPractice && searchMode === 'search-relief-doctors') || (!clinicSearchAddress && searchMode === 'search-clinics')} sx={{margin: 'auto'}}>
                    { searching && <CircularProgress size={30} /> }
                    Search
                  </Button>
                </CardActions>
              </Card>
            )}
            { searchSubmitted && (
              <>
                <Alert variant="outlined" severity="info" style={{margin: '20px'}}>
                  Sending a connection request does not guarantee a potential connection will be available on certain dates or at a certain rate. Connections (at this point in time) are meant to serve as a basic passing of contact information - the details of when exactly relief work can/will be provided are to be worked out directly between the two parties after mutually accepting a connection.
                </Alert>
                <div style={{display: 'flex'}}>
                  <Button size='medium' sx={{margin: '15px'}} onClick={() => setSearchSubmitted(false)}>{"<  Back"}</Button>
                  { searchSubmitted && <Typography sx={{margin: 'auto', paddingRight: '5em'}}>{searchMode === 'search-relief-doctors' ? docResults.length : clinicResults.length} search results</Typography> }
                  <br />
                </div>
                <Divider />
                { searchMode === 'search-relief-doctors' ? renderedDocList : renderedClinicList }
                { ((searchMode === 'search-relief-doctors' && docResults.length === 0) || (searchMode === 'search-clinics' && clinicResults.length === 0)) &&
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                  <Typography>
                    Unfortunately, there are no results available at this time.
                  </Typography>
                  <Typography>
                    The list is always growing, though, so check back soon!
                  </Typography>
                </div> }
              </>
            )}
            <Dialog fullWidth maxWidth='md' open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
              <DialogTitle>Confirm Practice Details</DialogTitle>
              <DialogContent style={{margin: 'auto', paddingTop: '10px'}}>
                <CreateOrEditPractice
                  editingPractice={selectedPractice}
                  onSuccess={(updatedPractice) => {setConfirmationDialogOpen(false); search(updatedPractice.address); refreshPracticeData() }}
                  onCancel={() => setConfirmationDialogOpen(false)}
                  compact
                />
              </DialogContent>
            </Dialog>
            <Dialog open={creditPurchaseDialogOpen} onClose={() => setCreditPurchaseDialogOpen(false)}>
              <DialogTitle>No Connection Credits Available</DialogTitle>
              <DialogContent>
                {
                  user?.connection_credits === 0 && (
                    <DialogContentText>
                      Please purchase connection credits to continue.
                    </DialogContentText>
                  )
                }
                {
                  user?.connection_credits !== 0 && (
                    <DialogContentText>
                      You have {user?.connection_credits} connection credits purchased, but they are all used on pending connections.
                      You can wait for a connection request to be declined, or purchase more credits.
                    </DialogContentText>
                  )
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setCreditPurchaseDialogOpen(false)}>Close</Button>
                <div style={{flex: '1 0 0'}} />
                <Button variant='contained' onClick={() => navigate('/user-profile/account')}>Go to Account</Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={successSnackbarOpen}
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              autoHideDuration={6000}
              onClose={() => setSuccessSnackbarOpen(false)}
            >
              <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                Connection request submitted!
              </Alert>
          </Snackbar>
          <Snackbar
            open={failureSnackbarOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            autoHideDuration={6000}
            onClose={() => setFailureSnackbarOpen(false)}
          >
            <Alert onClose={() => setFailureSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
              Error submitting connection request. Please email cfh@getcovered.health for support.
            </Alert>
          </Snackbar>
        </div>
    )
}
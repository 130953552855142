import { Avatar, Button, Card, Container, Divider, ListItemText, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import { List, ListItem, ListItemIcon } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import GroupsIcon from '@mui/icons-material/Groups';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import StoreIcon from '@mui/icons-material/Store';
import UserContext from '../UserContext';
import CoveredAppBar from './CoveredAppBar';

export default function UserView() {
    let { id } = useParams();

    // This is the logged in user
    var { user } = React.useContext(UserContext);

    // This is the user we are viewing
    const [userToView, setUserToView] = React.useState(null);

    // This is the (possible) connection request between the two users
    const [connectionRequest, setConnectionRequest] = React.useState(null);

    const [city, setCity] = React.useState(null);
    const [practices, setPractices] = React.useState([]);
    const token = localStorage.getItem("token");

    const cityRegex =  /([a-zA-Z]+, [A-Z]{2})[ ,]/;

    let largeScreen = useMediaQuery('(min-width:600px)');

    const getUserToViewInfo = () => {
        fetch(new URL(`api/users/${id}`, process.env.REACT_APP_BASE_URL), {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.detail) {
              console.log('oops :(')
              console.log(responseJson.detail)
            } else {
              setUserToView(responseJson);

              let cityMatches = RegExp(cityRegex, 'g').exec(responseJson.central_address);
              if (cityMatches && cityMatches[1]) {
                setCity(cityMatches[1])
              }
            }
          })
        
        fetch(new URL(`api/connection-requests/?user_one=${id}&user_two=${user.id}`, process.env.REACT_APP_BASE_URL), {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.detail) {
              console.log('oops :(')
              console.log(responseJson.detail)
            } else {
                if (responseJson.length) {
                    setConnectionRequest(responseJson[0])
                }
            }
          })

        fetch(new URL(`api/practices/?owner_id=${id}`, process.env.REACT_APP_BASE_URL), {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token,
          },
        })
        .then(response => response.json())
        .then(data => {
          if (data.detail) {
            console.log('oops')
            console.log(data.detail)
          } else {
            data.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p2.name > p1.name) ? -1 : 0)
            setPractices(data)
            console.log(data)
          }
        })

    }

    const acceptRequest = () => {
      fetch(new URL(`api/connection-requests/${connectionRequest.id}`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
            id: connectionRequest.id,
            requester_id: userToView.id,
            requestee_id: user.id,
            status: 'booked',
        })
      })
      .then((response) => response.json())
      .then((responseJson) => setConnectionRequest(responseJson))
    }

    // const declineRequest = () => {
    //   fetch(new URL(`api/connection-requests/${connectionRequest.id}`, process.env.REACT_APP_BASE_URL), {
    //     method: 'POST',
    //     headers: {
    //         'accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + token,
    //       },
    //     body: JSON.stringify({
    //         id: connectionRequest.id,
    //         requester_id: userToView.id,
    //         requestee_id: user.id,
    //         status: 'declined',
    //     })
    //   })
    //   .then((response) => response.json())
    //   .then((responseJson) => setConnectionRequest(responseJson))
    // }

    React.useEffect(() => {
        if (user && userToView === null) {
            getUserToViewInfo()
        }
    })

    // const RequesteeView = (props) => {
    //     return (
    //         <Container sx={{margin: 'auto', marginTop: '30px', marginBottom: '30px'}}>
    //             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
    //                 <Avatar src={request?.requester?.profile_photo_url} sx={{marginRight: '50px', height: '100px', width: '100px'}}/>
    //                 <div sx={{flexDirection: 'column'}}>
    //                     { <Typography variant='h5'>Contact: {request.requester.name}</Typography> }
    //                     { !props.anonymous && <Typography variant='subtitle1'>Contact Email: {request.requester.email}</Typography> }
    //                     { !props.anonymous && <Typography variant='subtitle1'>Contact Phone Number: {request.requester.phone_number}</Typography> }
    //                     { request.practice && (
    //                         <>
    //                             <Typography variant='subtitle1'>Practice Name: {request.practice.name}</Typography>
    //                             { !props.anonymous && <Typography variant='subtitle1'>Practice Address: {request.practice.address}</Typography> }
    //                             { props.anonymous && <Typography variant='subtitle1'>Received {request?.date}</Typography> }
    //                         </>
    //                     ) }
    //                     <Chip label={request.status === 'booked' ? 'accepted' : request.status} color="primary" variant="outlined" size="small" />
    //                 </div>
    //             </div>
    //             <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '30px'}}>
    //                 { request.practice && (
    //                     <Card sx={{marginRight: '30px'}}>
    //                         <CardContent>
    //                             <Typography gutterBottom>Patient Demographics</Typography>
    //                             <li>
    //                                 Avg {request.practice.average_patients_per_hour}/hour
    //                             </li>
    //                             <li>
    //                                 Max {request.practice.maximum_patients_per_hour}/hour
    //                             </li>
    //                             <li>
    //                                 {request.practice.average_percent_pediatric}% pediatric
    //                             </li>
    //                             <li>
    //                                 {request.practice.average_percent_geriatric}% geriatric
    //                             </li>
    //                         </CardContent>
    //                     </Card>
    //                 ) }
    //                 { request.practice && (
    //                     <div>
    //                         <Typography variant='h6'>Practice Information</Typography>
    //                         { (!!request.travel_distance || request.travel_distance === 0) && city && (
    //                             <li>
    //                                 { city } ({request.travel_distance.toFixed(1)} miles away)
    //                             </li>) }
    //                         { (!request.travel_distance && request.travel_distance !== 0) && city && (
    //                             <li>
    //                                 { city }
    //                             </li>) }
    //                         <li>
    //                             Typically ${request.practice.weekday_rate} weekday/${request.practice.weekend_rate} weekend
    //                         </li>
    //                         { request.practice.emr && (
    //                             <li>
    //                                 EHR Software: {request.practice.emr}
    //                             </li>
    //                         ) }
    //                         <li>
    //                             {request.practice.scribe_available ? 'Scribe available' : 'Scribe not available'}
    //                         </li>
    //                         <li>
    //                             {request.practice.techs_do_prelims ? 'Techs do prelims' : 'Techs do not do prelims'}
    //                         </li>
    //                     </div>
    //                 ) }
    //                 { request.practice && (
    //                     <Card sx={{marginLeft: '30px'}}>
    //                         <CardContent>
    //                             <Typography gutterBottom>Hours</Typography>
    //                             {!request.practice.hours && <li>Unknown</li>}
    //                             { request.practice.hours && request.practice.hours.map(day => (
    //                                 <li>
    //                                     {day}
    //                                 </li>
    //                             ))}
    //                         </CardContent>
    //                     </Card>
    //                 ) }
    //             </Container>
    //             { request.status === 'pending' &&
    //             <>
    //                 <Alert variant="outlined" severity="info" style={{marginBottom: '20px'}}>
    //                     Accepting this connection does not commit you to any specific work or specific rate. Connections (at this point in time) are meant to serve as a basic passing of contact information - the details of when exactly relief work can/will be provided are to be worked out directly between the two parties after accepting a connection.
    //                 </Alert>
    //                 <div style={{display: 'flex', justifyContent: 'space-around'}}>
    //                     <Button onClick={declineRequest} style={{width: '150px'}} variant='outlined'>Decline</Button>
    //                     <Button onClick={acceptRequest} style={{width: '150px'}} variant='contained'>Accept</Button>
    //                 </div>
    //             </>}
    //         </Container>
    //     )
    // }

    const availabilityTypes = (docResult) => {
        let result = []
        if (!docResult) {
            return result
        }
        if (docResult?.available_for_relief_dates_flexible) {
          result.push('Open/flexible')
        }

        if (docResult.estimated_relief_days_of_week) {
          let weeklyRecurringDays = []

          if (docResult.estimated_relief_days_of_week.includes('M')) {
            weeklyRecurringDays.push('Monday')
          }
          if (docResult.estimated_relief_days_of_week.includes('T')) {
            weeklyRecurringDays.push('Tuesday')
          }
          if (docResult.estimated_relief_days_of_week.includes('W')) {
            weeklyRecurringDays.push('Wednesday')
          }
          if (docResult.estimated_relief_days_of_week.includes('R')) {
            weeklyRecurringDays.push('Thursday')
          }
          if (docResult.estimated_relief_days_of_week.includes('F')) {
            weeklyRecurringDays.push('Friday')
          }
          if (docResult.estimated_relief_days_of_week.includes('A')) {
            weeklyRecurringDays.push('Saturday')
          }
          if (docResult.estimated_relief_days_of_week.includes('U')) {
            weeklyRecurringDays.push('Sunday')
          }

          result.push('Weekly recurring on ' + weeklyRecurringDays.join(', '))
        }

        const futureSpecificDateAvailability = docResult.availability.filter(date => new Date(date) >= new Date())

        if (futureSpecificDateAvailability.length) {
          result.push('Specific dates: ' + futureSpecificDateAvailability.join(', '))
        }

        return result
      }
    
    const anonymize = () => {
        // Temp for opening the gates
        return false

        // if (connectionRequest?.status === 'booked') {
        //     return false
        // }

        // if (connectionRequest?.preexisting_connection) {
        //     return false
        // }

        // if (connectionRequest?.requestee_id === user?.id) {
        //     return false
        // }

        // return true
    }

    return (
        <>
            <CoveredAppBar />
            <Container sx={{margin: 'auto', marginTop: '30px', marginBottom: '30px'}}>
                { connectionRequest && connectionRequest.status === 'booked' && (
                    <Alert variant="outlined" severity="success" style={{marginBottom: '20px'}}>
                        Congratulations! This request has been accepted, so the doctor's contact information is now visible. Your contact information has also been shared with this doctor.
                    </Alert>
                ) }
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Avatar src={userToView?.profile_photo_url} sx={{marginRight: '50px', height: '100px', width: '100px', filter: (anonymize() ? 'blur(18px)' : '')}}/>
                    <div sx={{flexDirection: 'column'}}>
                        { anonymize() ? <Typography variant='h5'>Pending Connection Request</Typography> : <Typography variant='h5'>{userToView?.name}</Typography> }
                        {/* Commented out below bc appears to be a bug with date not getting set */}
                        {/* { connectionRequest?.status === 'pending' && <Typography variant='subtitle1'>Sent {connectionRequest?.date}</Typography> } */}
                        { connectionRequest &&  <Chip label={connectionRequest?.status === 'booked' ? 'accepted' : connectionRequest?.status} color="primary" variant="outlined" size="small" /> }
                        { !connectionRequest &&  <Chip label={'not connected'} color="primary" variant="outlined" size="small" /> }
                    </div>
                </div>
                { (connectionRequest?.status === 'pending' && connectionRequest.requestee_id === user.id) &&
                    <div style={{marginTop: '10px'}}>
                        <Alert variant="outlined" severity="info" style={{marginBottom: '20px'}}>
                            Accepting this connection does not commit you to any specific work or specific rate. Connections (at this point in time) are meant to serve as a basic passing of contact information - the details of when exactly relief work can/will be provided are to be worked out directly between the two parties after accepting a connection.
                        </Alert>
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {/* <Button onClick={declineRequest} style={{width: '150px'}} variant='outlined'>Decline</Button> */}
                            <Button onClick={acceptRequest} style={{width: '150px'}} variant='contained'>Accept</Button>
                        </div>
                    </div>
                }
                { connectionRequest?.status === 'booked' && <div style={{display: 'flex', flexDirection: 'row', marginLeft: largeScreen ? '28%' : 0, padding: '20px'}}>
                    { largeScreen && <div style={{marginRight: '20px', width: '7em'}}>
                        <Typography variant='h6'>Contact Information</Typography>
                    </div>}
                    <Divider orientation='vertical' flexItem />
                    <List style={{}}>
                        { !largeScreen &&
                            <Typography variant='h5' style={{paddingLeft: '20px'}}>Contact Information</Typography>
                        }
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText>
                                { userToView?.phone_number }
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <EmailIcon />
                            </ListItemIcon>
                            <ListItemText>
                                { userToView?.email }
                            </ListItemText>
                        </ListItem>
                        { userToView?.license_number && <ListItem>
                            <ListItemIcon>
                                <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText>
                                { userToView?.license_number }
                            </ListItemText>
                        </ListItem> }
                    </List>
                </div> }
                <div style={{}}>
                { userToView?.is_relief_doc && (
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: largeScreen ? '28%' : 0, padding: '20px'}}>
                        { largeScreen && <div style={{marginRight: '20px', width: '7em'}}>
                            <Typography variant='h6'>Fill-In Availability</Typography>
                        </div> }
                        <Divider orientation='vertical' flexItem />
                        <List style={{}}>
                            { !largeScreen &&
                                <Typography variant='h5' style={{paddingLeft: '20px'}}>Fill-In Availability</Typography>
                            }
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOnIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    { city } (will travel {userToView?.max_travel_distance_miles} miles)
                                </ListItemText>
                            </ListItem>
                            { userToView?.years_of_experience && 
                                <ListItem>
                                    <ListItemIcon>
                                        <MilitaryTechIcon />
                                    </ListItemIcon>
                                    <ListItemText>
                                        { userToView?.years_of_experience } years experience
                                    </ListItemText>
                                </ListItem> }
                            <ListItem alignItems="flex-start">
                                <ListItemIcon>
                                    <CalendarMonthIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Dates (last updated {new Date(userToView?.availability_last_updated).toLocaleDateString('en-us')}):
                                    {availabilityTypes(userToView).map(availabilityType => (
                                      <li key={availabilityType} style={{paddingLeft: '10px'}}>{ availabilityType }</li>
                                    ))}
                                </ListItemText>
                            </ListItem>
                            { userToView?.max_patients_per_hour && <ListItem>
                                <ListItemIcon>
                                    <GroupsIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    { userToView?.max_patients_per_hour } max patients/hour
                                </ListItemText>
                            </ListItem> }
                        </List> 
                    </div>
                )}
                { practices && (
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: largeScreen ? '28%' : 0, padding: '20px'}}>
                        { largeScreen && <div style={{marginRight: '20px', width: '7em'}}>
                            <Typography variant='h6'>Clinics ({practices.length})</Typography>
                        </div> }
                        <Divider orientation='vertical' flexItem />
                        <List style={{}}>
                            { !largeScreen &&
                                <Typography variant='h5' style={{paddingLeft: '20px'}}>Clinics ({practices.length})</Typography>
                            }
                            {practices.map((practice) =>
                                <>
                                    <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                            <StoreIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <div style={{display: 'flex', flexDirection: largeScreen ? 'row' : 'column'}}>
                                                <div>
                                                    <Typography><b>{ practice.name }</b></Typography>
                                                    <Typography>{ practice.address }</Typography>
                                                    <Typography>&bull; Averages {practice.average_patients_per_hour} patients per hour, maximum {practice.maximum_patients_per_hour}</Typography>
                                                    <Typography>&bull; Typically ${practice.weekday_rate} weekday, ${practice.weekend_rate} weekend</Typography>
                                                    <Typography>&bull; {practice.average_percent_pediatric}% pediatric, {practice.average_percent_geriatric}% geriatric</Typography>
                                                </div>
                                                { !!practice.hours?.length && (
                                                    <Card style={{padding: '10px', marginLeft: '20px'}}>
                                                        { practice.hours.map((day) => 
                                                            <Typography variant='subtitle2' style={{lineHeight: '1.2em'}}>{day}</Typography>
                                                        )}
                                                    </Card>
                                                )}
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </div>
                )}
                </div>
            </Container>
        </>
    )
}
import * as React from 'react';
import { Button, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import UserContext from '../UserContext';

import { practiceErrorsPresent } from './practiceHelpers';

export const CreateOrEditPractice = (props) => {
  var { user } = React.useContext(UserContext);
  const token = localStorage.getItem("token");
  const auth = {auth: {token: token, email: user?.email}};

  const errorValues = ['', null, undefined];

  const [name, setName] = React.useState(props.editingPractice?.name ?? '');
  const [nameError, setNameError] = React.useState(false);

  const [address, setAddress] = React.useState(props.editingPractice?.address ?? '');
  const [addressError, setAddressError] = React.useState(props.editingPractice && errorValues.includes(address));
  const [phoneNumber, setPhoneNumber] = React.useState(props.editingPractice?.phone_number ?? '');

  const [weekdayRate, setWeekdayRate] = React.useState(props.editingPractice?.weekday_rate ?? '');
  const [weekdayRateError, setWeekdayRateError] = React.useState(props.editingPractice && errorValues.includes(weekdayRate));
  const [weekdayLunchBreakDurationMinutes, setWeekdayLunchBreakDurationMinutes] = React.useState(props.editingPractice?.weekday_lunch_break_duration_minutes ?? '');
  const [weekdayLunchBreakDurationMinutesError, setWeekdayLunchBreakDurationMinutesError] = React.useState(props.editingPractice && errorValues.includes(weekdayLunchBreakDurationMinutes));
  const [weekendRate, setWeekendRate] = React.useState(props.editingPractice?.weekend_rate ?? '');
  const [weekendRateError, setWeekendRateError] = React.useState(props.editingPractice && errorValues.includes(weekendRate));
  const [weekendLunchBreakDurationMinutes, setWeekendLunchBreakDurationMinutes] = React.useState(props.editingPractice?.weekend_lunch_break_duration_minutes ?? '');
  const [weekendLunchBreakDurationMinutesError, setWeekendLunchBreakDurationMinutesError] = React.useState(props.editingPractice && errorValues.includes(weekendLunchBreakDurationMinutes));
  const [averagePatientsPerHour, setAveragePatientsPerHour] = React.useState(props.editingPractice?.average_patients_per_hour ?? '');
  const [averagePatientsPerHourError, setAveragePatientsPerHourError] = React.useState(props.editingPractice && errorValues.includes(averagePatientsPerHour));
  const [maximumPatientsPerHour, setMaximumPatientsPerHour] = React.useState(props.editingPractice?.maximum_patients_per_hour ?? '');
  const [maximumPatientsPerHourError, setMaximumPatientsPerHourError] = React.useState(props.editingPractice && errorValues.includes(maximumPatientsPerHour));
  const [scribeAvailable, setScribeAvailable] = React.useState(props.editingPractice?.scribe_available ?? false);
  const [techsDoPrelims, setTechsDoPrelims] = React.useState(props.editingPractice?.techs_do_prelims ?? false);
  const [averagePercentPediatric, setAveragePercentPediatric] = React.useState(props.editingPractice?.average_percent_pediatric ?? '');
  const [averagePercentPediatricError, setAveragePercentPediatricError] = React.useState(props.editingPractice && errorValues.includes(averagePercentPediatric));
  const [averagePercentGeriatric, setAveragePercentGeriatric] = React.useState(props.editingPractice?.average_percent_geriatric ?? '');
  const [averagePercentGeriatricError, setAveragePercentGeriatricError] = React.useState(props.editingPractice && errorValues.includes(averagePercentGeriatric));
  const [emr, setEMR] = React.useState(props.editingPractice?.emr ?? '');
  const [clinicCurrentHours, setClinicCurrentHours] = React.useState(props.editingPractice?.hours ?? []);

  const [savingPractices, setSavingPractices] = React.useState(false);
  const [practicesSaved, setPracticesSaved] = React.useState(false);

  React.useEffect(() => {
    var addressInput = document.getElementById('newPracticeAddress');

    var options = {
        componentRestrictions: { country: "us" },
        fields: ['formatted_address', 'current_opening_hours', 'name']
    }

    var addressAutocomplete = new window.google.maps.places.Autocomplete(addressInput, options);
    addressAutocomplete.addListener('place_changed', () => {
        let clinic = addressAutocomplete.getPlace();
        setAddress(clinic.formatted_address);
        clinic.current_opening_hours?.weekday_text && setClinicCurrentHours(clinic.current_opening_hours.weekday_text);
    });
  })

  let createdOrUpdatedPractice = () => ({
    name: name,
    address: address,
    phone_number: phoneNumber,
    weekday_rate: weekdayRate,
    weekday_lunch_break_duration_minutes: weekdayLunchBreakDurationMinutes,
    weekend_rate: weekendRate,
    weekend_lunch_break_duration_minutes: weekendLunchBreakDurationMinutes,
    average_patients_per_hour: averagePatientsPerHour,
    maximum_patients_per_hour: maximumPatientsPerHour,
    average_percent_pediatric: averagePercentPediatric,
    average_percent_geriatric: averagePercentGeriatric,
    emr: emr,
    hours: clinicCurrentHours,
  })

  const updatePractices = () => {
    setSavingPractices(true)
    if (!props.editingPractice) {
      fetch(new URL(`api/practices`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
            ...auth,
            practice: {
              owner_id: user.id,
              name: name,
              address: address,
              state: address.split(' ')[address.split(' ').length - 3], // TODO: fix this bug, doesn't work if they pick a city
              type: 'optometry',
              weekday_rate: weekdayRate,
              weekend_rate: weekendRate,
              average_patients_per_hour: averagePatientsPerHour,
              maximum_patients_per_hour: maximumPatientsPerHour,
              weekday_lunch_break_duration_minutes: weekdayLunchBreakDurationMinutes,
              weekend_lunch_break_duration_minutes: weekendLunchBreakDurationMinutes,
              scribe_available: scribeAvailable,
              techs_do_prelims: techsDoPrelims,
              average_percent_pediatric: averagePercentPediatric,
              average_percent_geriatric: averagePercentGeriatric,
              emr: emr,
              hours: clinicCurrentHours,
            }
        })
      })
      .then(response => response.json())
      .then(responseJson => {
        setSavingPractices(false)
        setPracticesSaved(true)
        setTimeout(() => {
          setPracticesSaved(false)
          props.onSuccess(responseJson)
        }, 500)

        if (responseJson.detail) {
          console.log('oops error')
        } else {
          setName(null)
          setAddress(null)
          setPhoneNumber(null)
          setWeekdayRate(null)
        }
      })
    } else {
      fetch(new URL(`api/practices/${props.editingPractice.id}`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
            ...auth,
            practice: {
              id: props.editingPractice.id,
              name: name,
              address: address,
              state: address.split(' ')[address.split(' ').length - 3], // TODO: fix this bug, doesn't work if they pick a city
              weekday_rate: weekdayRate,
              weekend_rate: weekendRate,
              average_patients_per_hour: averagePatientsPerHour,
              maximum_patients_per_hour: maximumPatientsPerHour,
              weekday_lunch_break_duration_minutes: weekdayLunchBreakDurationMinutes,
              weekend_lunch_break_duration_minutes: weekendLunchBreakDurationMinutes,
              scribe_available: scribeAvailable,
              techs_do_prelims: techsDoPrelims,
              average_percent_pediatric: averagePercentPediatric,
              average_percent_geriatric: averagePercentGeriatric,
              emr: emr,
              hours: clinicCurrentHours
            }
        })
      })
      .then(response => response.json())
      .then(responseJson => {
        setSavingPractices(false)
        setPracticesSaved(true)
        setTimeout(() => {
          setPracticesSaved(false)
          props.onSuccess(responseJson)
        }, 500)

        if (responseJson.detail) {
          console.log('oops error')
          console.log(responseJson.detail)
        } else {
          console.log('yay')
        }
      })
  }
}

  return (
    <>
      <Box>
          <TextField
            id="newPracticeName"
            required
            defaultValue={name}
            error={nameError}
            onChange={(e) => {setName(e.target.value); setNameError(errorValues.includes(e.target.value))}}
            type="text"
            label="Name"
            sx={{paddingBottom: '20px', paddingRight: '10px', width: '200px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <TextField
            id="newPracticeAddress"
            required
            defaultValue={address}
            error={addressError}
            onChange={(e) => {setAddress(e.target.value); setAddressError(errorValues.includes(e.target.value))}}
            type="text"
            label="Address"
            sx={{paddingBottom: '20px', width: '350px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <br />

          <h4>Typical Rates</h4>
          <TextField
            id="weekdayRate"
            required
            defaultValue={weekdayRate}
            error={weekdayRateError}
            onChange={(e) => { setWeekdayRate(e.target.value); setWeekdayRateError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Weekday (USD)"
            sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <TextField
            id="weekendRate"
            required
            defaultValue={weekendRate}
            error={weekendRateError}
            onChange={(e) => {setWeekendRate(e.target.value); setWeekendRateError(errorValues.includes(e.target.value))}}
            type="number"
            label="Weekend (USD)"
            sx={{paddingBottom: '20px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />

          <h4>Standard Operations</h4>
          <FormControl error={errorValues.includes(emr)} sx={{width: '275px', marginBottom: '20px'}}>
            <InputLabel id="emr-select-label">EMR Software *</InputLabel>
            <Select
              required
              labelId="emr-select-label"
              id="emr-select"
              value={emr}
              label="EMR Software"
              onChange={(e) => setEMR(e.target.value)}
              size={props.compact ? 'small' : 'medium'}
            >
              <MenuItem value={'Crystal'}>Crystal</MenuItem>
              <MenuItem value={'Compulink'}>Compulink</MenuItem>
              <MenuItem value={'OfficeMate'}>OfficeMate</MenuItem>
              <MenuItem value={'MaximEyes'}>MaximEyes</MenuItem>
              <MenuItem value={'iTrust'}>iTrust</MenuItem>
              <MenuItem value={'Uprise'}>Uprise</MenuItem>
              <MenuItem value={'eClinicalWorks'}>eClinicalWorks</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
          <br/>
          <TextField
            id="weekdayLunchBreakDurationMinutes"
            required
            defaultValue={weekdayLunchBreakDurationMinutes}
            error={weekdayLunchBreakDurationMinutesError}
            onChange={(e) => { setWeekdayLunchBreakDurationMinutes(e.target.value); setWeekdayLunchBreakDurationMinutesError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Weekday lunch duration (minutes)"
            sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <TextField
            id="weekendLunchBreakDurationMinutes"
            required
            defaultValue={weekendLunchBreakDurationMinutes}
            error={weekendLunchBreakDurationMinutesError}
            onChange={(e) => { setWeekendLunchBreakDurationMinutes(e.target.value); setWeekendLunchBreakDurationMinutesError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Weekend lunch duration (minutes)"
            sx={{paddingBottom: '20px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <br />
          <TextField
            id="averagePatientsPerHour"
            required
            defaultValue={averagePatientsPerHour}
            error={averagePatientsPerHourError}
            onChange={(e) => { setAveragePatientsPerHour(e.target.value); setAveragePatientsPerHourError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Average Patients/Hour"
            sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <TextField
            id="maximumPatientsPerHour"
            required
            defaultValue={maximumPatientsPerHour}
            error={maximumPatientsPerHourError}
            onChange={(e) => { setMaximumPatientsPerHour(e.target.value); setMaximumPatientsPerHourError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Max Patients/Hour"
            sx={{paddingBottom: '20px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <br />
          <TextField
            id="averagePercentPediatric"
            required
            defaultValue={averagePercentPediatric}
            error={averagePercentPediatricError}
            onChange={(e) => { setAveragePercentPediatric(e.target.value); setAveragePercentPediatricError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Average Percent Pediatric"
            sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <TextField
            id="averagePercentGeriatric"
            required
            defaultValue={averagePercentGeriatric}
            error={averagePercentGeriatricError}
            onChange={(e) => { setAveragePercentGeriatric(e.target.value); setAveragePercentGeriatricError(errorValues.includes(e.target.value)) }}
            type="number"
            label="Average Percent Geriatric"
            sx={{paddingBottom: '20px', width: '275px'}}
            size={props.compact ? 'small' : 'medium'}
          />
          <br />
          <FormControlLabel
            value="scribeAvailable"
            control={<Checkbox checked={scribeAvailable || false} onChange={(e) => {setScribeAvailable(e.target.checked)}}/>}
            label="Scribe typically available"
            labelPlacement="end"
          />
          <FormControlLabel
            value="techsDoPrelims"
            control={<Checkbox checked={techsDoPrelims || false} onChange={(e) => {setTechsDoPrelims(e.target.checked)}}/>}
            label="Techs typically do prelims"
            labelPlacement="end"
          />
      </Box>
      <Box textAlign='center'>
          <Button variant='outline' onClick={props.onCancel} style={{marginTop: '10px'}}>
            Cancel
          </Button>
          <Button variant='contained' disabled={savingPractices} onClick={() => !practiceErrorsPresent([createdOrUpdatedPractice()]).length && updatePractices()} style={{marginTop: '10px'}}>
            { practicesSaved ? <CheckIcon /> : 'Save' }
          </Button>
      </Box>
    </>
  )
}
import * as React from 'react';
import CoveredAppBar from './CoveredAppBar';

export default function AlmostDone() {

    return (
        <>
            <CoveredAppBar canNav={false}/>
            <h4 style={{textAlign: 'center'}}>
                Thank you for your early access registration to Covered! We are putting the finishing touches on the website and will send you an email as soon as we're ready.
                <br/>
                <br/>
                Thank you for your continued support!
                <br/>
                <br/>
                - Christina & Judy, Co-Founders
                <br/>
                <br/>
                P.S. Feel free to send any questions or feedback to cfh@getcovered.health in the meantime!
            </h4>
        </>
    )
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import UserContext from '../UserContext';
import { Typography } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction';

export default function CoveredAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { user, approvedUsers } = React.useContext(UserContext);

    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const selectHomeMenuItem = () => {
      handleClose();
      navigate('/');
    }

    const selectSearchMenuItem = () => {
      handleClose();
      navigate('/search');
    }

    const selectMyProfileMenuItem = () => {
      handleClose();
      navigate('/user-profile');
    }

    const userApproved = approvedUsers?.includes(user?.id) || approvedUsers?.includes('all');

    return (
      <>
        <AppBar position="sticky" sx={{bgcolor: '#5ab8c2',zIndex: (theme) => theme.zIndex.drawer + 1}}>
          <Toolbar>
            { (user && (userApproved || process.env.REACT_APP_BASE_URL !== 'https://my.getcovered.health/' ) && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  sx={{ mr: 0 }}
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={selectHomeMenuItem}>Home</MenuItem>
                  <MenuItem onClick={selectSearchMenuItem}>New Search</MenuItem>
                  <MenuItem onClick={selectMyProfileMenuItem}>My Profile</MenuItem>
                </Menu>
              </>
            ))}
            <img onClick={() => navigate('/')} src='/textlogo.svg' alt='logo' style={{cursor: 'pointer', height: '50px', padding: '10px'}}></img>
            <div id="app-bar-spacer" style={{width: '100%'}}></div>
            <ActionButtons />
          </Toolbar>
        <div style={{padding: '0.3em 0 0.3em 0', width: '100vw', backgroundColor: '#FFEB5A', textAlign: 'center'}}>
          <ConstructionIcon sx={{color: 'black', verticalAlign: 'text-bottom'}}/>
          <Typography style={{display: 'inline', verticalAlign: 'text-bottom', marginLeft: '5px', color: 'black'}}>
            Covered is provided free of charge during beta testing. Thank you for your support!
          </Typography>
        </div>
        </AppBar>
      </>
    )
}

function ActionButtons() {
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate();

  if (user) {
      return (
          <Button
            onClick={() => {
              localStorage.setItem("token", null)
              localStorage.setItem("practice", null)
              localStorage.setItem("userId", null)
              localStorage.setItem("userEmail", null)

              setUser(null);

              navigate('/sign-in', {replace: true});
            }}
            color="inherit"
            sx={{minWidth: '100px'}}>
            Sign Out
          </Button>
      )
  }
}

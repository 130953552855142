import * as React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { Typography } from '@mui/material';
import CheckoutForm from './CheckoutForm';

let stripeKey = "pk_test_51MIFsELbKuzkt8PaLDK7xawUOKVoJb5FLloFHaFu1EvtdaP64uzYT3g2fO1vIEpCLCKJ4b5wZByev1SZHvIURcWh00C4aaaQ2w";
if (process.env.REACT_APP_BASE_URL === 'https://my.getcovered.health/') {
  stripeKey = 'pk_live_51MIFsELbKuzkt8PaZc81vSwONu2cpPZvwRorkXCzVMlbHDCgKEKlW5xhanm97UrFvuzQexI10xOvA4HSHW7wLzJM00x6yaS1Qm'
}
const stripePromise = loadStripe(stripeKey);


export default function PaymentDialog(props) {
    const [clientSecret, setClientSecret] = React.useState(null);
    const [closing, setClosing] = React.useState(false);

    const token = localStorage.getItem('token');

    React.useEffect(() => {
      if (!closing) {
        if (props.amount && !clientSecret) {
            fetch(new URL(`/api/${props.userId}/create-payment-intent`, process.env.REACT_APP_BASE_URL), {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
              body: JSON.stringify({ amount: props.amount }),
            })
              .then((res) => res.json())
              .then((data) => {
                  if (data.detail) {
                      console.log(data.detail)
                  } else {
                    setClientSecret(data.clientSecret)
                  }
              });
        }
      }
    });

    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };

    return (
        <Dialog open={props.open} onClose={() => { setClosing(true); setClientSecret(null); props.close(); setClosing(false); }}>
          <DialogTitle>Purchase Connection Credits</DialogTitle>
          <DialogContent>
              <Typography variant='h4'>{props.numCredits} credits</Typography>
              Total: ${props.amount / 100}
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              )}
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
    )
}
import * as React from 'react';
import { Button, TextField, useMediaQuery } from '@mui/material';
import CoveredAppBar from './CoveredAppBar';
import { Calendar, DateObject } from "react-multi-date-picker"
import AddIcon from '@mui/icons-material/Add';
import { Box, Drawer, Toolbar, List, ListItem, ListItemButton, ListItemIcon, Divider, ListItemText, Typography } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import DatePicker from "react-multi-date-picker";
import { Alert, Snackbar } from '@mui/material';
import PaymentDialog from './PaymentDialog';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { CreateOrEditPractice } from './CreateOrEditPractice';
import UserContext from '../UserContext';
import ConnectionsList from './ConnectionsList';

import { useNavigate }  from 'react-router-dom';
import { uploadProfilePhoto } from './S3Interfaces';
import { practiceErrorsPresent } from './practiceHelpers';
import { handlePhoneNumber } from '../utils/utils';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const sections = ['Account', 'Basics', 'Notifications', 'Relief Availability', 'My Clinics', 'Connections'];

export default function UserProfile(props) {
    const token = localStorage.getItem("token");
    var { user, setUser } = React.useContext(UserContext);
    const auth = {auth: {token: token, email: user?.email}};
    const navigate = useNavigate();
    let largeScreen = useMediaQuery('(min-width:600px)');

    const errorValues = ['', null, undefined];

    // loading is used to deactivate some things while we process credit additions from the stripe redirect
    const [loading, setLoading] = React.useState(false);

    const [drawerOpen, setDrawerOpen] = React.useState(largeScreen);

    const [selectedSection, setSelectedSection] = React.useState(props.selectedSection ?? sections[0]);
    const [userDataLoaded, setUserDataLoaded] = React.useState(false);
    const [practiceDataLoaded, setPracticeDataLoaded] = React.useState(false);
    const [completedPaymentsProcessed, setCompletedPaymentsProcessed] = React.useState(false);

    const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false);
    const [numCreditsToPurchase, setNumCreditsToPurchase] = React.useState(null);
    const [purchaseCreditsAmount, setPurchaseCreditsAmount] = React.useState(null);

    const [name, setName] = React.useState(null);
    const [phoneNumber, setPhoneNumber] = React.useState(null);
    const [licenseNumber, setLicenseNumber] = React.useState(null);
    const [yearsOfExperience, setYearsOfExperience] = React.useState(null);
    const [profileBasicsError, setProfileBasicsError] = React.useState(false);

    const [isClinic, setIsClinic] = React.useState(false);
    const [isReliefDoc, setIsReliefDoc] = React.useState(null);
    const [connectionCredits, setConnectionCredits] = React.useState(null);

    const [textOptIn, setTextOptIn] = React.useState(user?.text_opt_in ?? false);
    const [emailOptIn, setEmailOptIn] = React.useState(user?.email_opt_in ?? false);

    const [centralAddress, setCentralAddress] = React.useState(null);
    const [maxTravelDistanceMiles, setMaxTravelDistanceMiles] = React.useState(null);
    const [dailyRate, setDailyRate] = React.useState(user?.daily_rate ?? '');
    const [dailyRateWeekendHoliday, setDailyRateWeekendHoliday] = React.useState(user?.daily_rate ?? '');
    const [datesFlexible, setDatesFlexible] = React.useState(user?.available_for_relief_dates_flexible ?? false);
    const [profilePhotoUrl, setProfilePhotoUrl] = React.useState(user?.profile_photo_url);
    const [maxPatientsPerHour, setMaxPatientsPerHour] = React.useState(user?.max_patients_per_hour ?? null);

    const [availability, setAvailability] = React.useState([]);
    const [reliefDocEstimatedReliefDaysOfWeek, setReliefDocEstimatedReliefDaysOfWeek] = React.useState(user?.estimated_relief_days_of_week?.split('') ?? []);
    const [availableForReliefRecurring, setAvailableForReliefRecurring] = React.useState(user?.available_for_relief_recurring ?? false);
    const [recurringAvailabilityStartDate, setRecurringAvailabilityStartDate] = React.useState(user?.recurring_availability_start_date ?? null);
    const [recurringAvailabilityEndDate, setRecurringAvailabilityEndDate] = React.useState(user?.recurring_availability_end_date ?? null);

    const [practices, setPractices] = React.useState([]);
    const [creatingNewPractice, setCreatingNewPractice] = React.useState(false);
    const [editingPractice, setEditingPractice] = React.useState(null);
    const [practicesWithErrors, setPracticesWithErrors] = React.useState([]);

    const [savingProfile, setSavingProfile] = React.useState(false);
    const [profileSaved, setProfileSaved] = React.useState(false);

    // Connections state
    const [addingKnownUsers, setAddingKnownUsers] = React.useState(props.selectedSubsection === 'known-users');
    const [connectionPhoneNumber, setConnectionPhoneNumber] = React.useState('');
    const [searchingForExistingConnection, setSearchingForExistingConnection] = React.useState(false);
    const [addedConnections, setAddedConnections] = React.useState([]);
    const [successfulInviteAlertDisplayed, setSuccessfulInviteAlertDisplayed] = React.useState(false);
    const [sentInvites, setSentInvites] = React.useState([]);

    React.useEffect(() => {
        if (!userDataLoaded && user) {
          updateUserData(user)
        }

        if (!completedPaymentsProcessed && user) {
          processAnyCompletedPayments()
        }

        // Get Google address input configured
        var centralAddressInput = document.getElementById('centralAddress');
        var options = {
            componentRestrictions: { country: "us" },
            fields: ['formatted_address']
        }

        if (window.google) {
          if (centralAddressInput) {
            centralAddressInput.value = user?.central_address || ''
            var centralAddressAutocomplete = new window.google.maps.places.Autocomplete(centralAddressInput, options);
            centralAddressAutocomplete.addListener('place_changed', () => {
                setCentralAddress(centralAddressAutocomplete.getPlace().formatted_address);
            });

          }
        }

        if (!practiceDataLoaded && user) {
          refreshPracticeData()
        }
    })

    const processAnyCompletedPayments = () => {
      // Process any payments that were completed
      const paymentIntent = new URLSearchParams(window.location.search).get(
        "payment_intent"
      );

      if (!paymentIntent) {
        setCompletedPaymentsProcessed(true)
        return
      }

      setLoading(true);
      fetch(new URL(`api/users/${user.id}/update`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
          ...auth,
          data: {
            payment_intent: paymentIntent,
          },
        })
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.detail) {
            console.log('oh no')
          } else {
            setLoading(false)
            setConnectionCredits(data)
            setUser({...user, connection_credits: data})
            window.history.replaceState({}, document.title, "/user-profile/account");
            setLoading(false);
            setCompletedPaymentsProcessed(true)
          }
        })
    }

    const updateUserData = (user) => {
      setProfilePhotoUrl(user.profile_photo_url)
      setName(user.name)
      setPhoneNumber(user.phone_number)
      setYearsOfExperience(user.years_of_experience)
      setLicenseNumber(user.license_number)

      setIsClinic(user.is_clinic)
      setIsReliefDoc(user.is_relief_doc)
      setConnectionCredits(user.connection_credits)

      setMaxTravelDistanceMiles(user.max_travel_distance_miles)
      setCentralAddress(user.central_address)
      setDatesFlexible(user.available_for_relief_dates_flexible)
      setAvailableForReliefRecurring(user.available_for_relief_recurring)
      setReliefDocEstimatedReliefDaysOfWeek(user.estimated_relief_days_of_week?.split('') || [])
      setRecurringAvailabilityStartDate(user.recurring_availability_start_date)
      setRecurringAvailabilityEndDate(user.recurring_availability_end_date)
      setDailyRate(user.daily_rate)
      setDailyRateWeekendHoliday(user.daily_rate_weekend_holiday)
      setMaxPatientsPerHour(user.max_patients_per_hour)

      setAvailability(user.availability.map(date => new DateObject(date)))

      if ([
        errorValues.includes(user.name),
        errorValues.includes(user.phone_number),
      ].includes(true)) {
        setProfileBasicsError(true)
      }

      setUserDataLoaded(true)
    }

    const refreshPracticeData = () => {
      fetch(new URL(`api/practices/?owner_id=${user.id}`, process.env.REACT_APP_BASE_URL), {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.detail) {
          console.log('oops')
          console.log(data.detail)
        } else {
          data.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p2.name > p1.name) ? -1 : 0)
          setPractices(data)
          setPracticeDataLoaded(true)

          setPracticesWithErrors(practiceErrorsPresent(data));
        }
      })
    }

    const reliefAvailabilityErrors = () => {
      const errors = [
        errorValues.includes(maxTravelDistanceMiles),
        errorValues.includes(centralAddress),
        errorValues.includes(dailyRate),
        errorValues.includes(dailyRateWeekendHoliday),
        errorValues.includes(maxPatientsPerHour),
        errorValues.includes(yearsOfExperience),
        errorValues.includes(licenseNumber),
      ]

      return errors.includes(true)
    }

    const handleUpdateAvailability = (dates) => {
        const newAvailability = dates.map(date => date.format('YYYY-MM-DD'))
        setAvailability(newAvailability)

        fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
          method: 'POST',
          headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
          body: JSON.stringify({
            ...auth,
            user: {
              ...user,
              availability: newAvailability
            }
          })
        })
    }

    const updateProfilePhoto = (profilePhotoUrl) => {
        const updatedProfile = {
          id: user.id,
          email: user.email,
          profile_photo_url: profilePhotoUrl
        }

        fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
            body: JSON.stringify({
              ...auth,
              user: updatedProfile
            })
        })
        .then(response => response.json())
        .then(data => {
          setSavingProfile(false)
          setProfileSaved(true)
          setTimeout(() => {
            setProfileSaved(false)
          }, 500)

          if (data.detail) {
            console.log('oops')
            console.log(data.detail)
          } else {
            setUser(data)
          }
        })

    }

    const profileBasicsErrorsPresent = () => {
      const errors = [
        errorValues.includes(name),
        errorValues.includes(phoneNumber),
      ]

      return errors.includes(true)
    }

    const updateIsClinic = (updatedIsClinic) => {
        setIsClinic(updatedIsClinic);
        fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
            body: JSON.stringify({
              ...auth,
              user: {
                id: user.id,
                email: user.email,
                is_clinic: updatedIsClinic,
              }
            })
        })
        .then(response => response.json())
        .then(data => {
          if (data.detail) {
            console.log('oops')
            console.log(data.detail)
          } else {
            setUser(data)
          }
        })

    }

    const updateProfile = () => {
        setSavingProfile(true)

        // Temp fix to re-format start/end dates until this can be streamlined
        let formattedRecurringAvailabilityStartDate = recurringAvailabilityStartDate
        if (recurringAvailabilityStartDate && typeof(recurringAvailabilityStartDate) == 'object') {
          formattedRecurringAvailabilityStartDate = recurringAvailabilityStartDate.format('MM-DD-YYYY')
        }
        let formattedRecurringAvailabilityEndDate = recurringAvailabilityEndDate
        if (recurringAvailabilityEndDate && typeof(recurringAvailabilityEndDate) === 'object') {
          formattedRecurringAvailabilityEndDate = recurringAvailabilityEndDate.format('MM-DD-YYYY')
        }

        const newProfile = {
          id: user.id,
          email: user.email,
          name: name,
          phone_number: phoneNumber,
          central_address: centralAddress,
          max_travel_distance_miles: maxTravelDistanceMiles,
          daily_rate: dailyRate,
          daily_rate_weekend_holiday: dailyRateWeekendHoliday,
          available_for_relief_dates_flexible: datesFlexible,
          is_clinic: isClinic,
          is_relief_doc: isReliefDoc,
          years_of_experience: yearsOfExperience,
          license_number: licenseNumber,
          estimated_relief_days_of_week: reliefDocEstimatedReliefDaysOfWeek.join(''),
          recurring_availability_start_date: formattedRecurringAvailabilityStartDate,
          recurring_availability_end_date: formattedRecurringAvailabilityEndDate,
          available_for_relief_recurring: availableForReliefRecurring,
          max_patients_per_hour: maxPatientsPerHour,
          text_opt_in: textOptIn,
          email_opt_in: emailOptIn,
        }

        fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
            body: JSON.stringify({
              ...auth,
              user: newProfile
            })
        })
        .then(response => response.json())
        .then(data => {
          setSavingProfile(false)
          setProfileSaved(true)
          setTimeout(() => {
            setProfileSaved(false)
          }, 500)

          if (data.detail) {
            console.log('oops')
            console.log(data.detail)
          } else {
            setUser(data)
            setProfileBasicsError(false)
          }
        })
    }

    const sendUserInvite = (phoneNumber) => {
      fetch(new URL('api/user-invites', process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
          ...auth,
          new_invite: {
            invitee_phone_number: phoneNumber,
            inviter_id: user.id
          },
        })
      })
      .then(response => response.json())
      .then(data => {
        setSuccessfulInviteAlertDisplayed(true)
        setSentInvites([phoneNumber, ...sentInvites])
      })
    }

    const userPractices = (
      <>
        <FormControlLabel
          value="isClinic"
          control={<Checkbox checked={isClinic || false} onChange={(e) => {updateIsClinic(e.target.checked)}}/>}
          label="I am looking for relief doctors"
          labelPlacement="end"
        />
        { practices.map(practice => {
        return (
          <Box key={practice.id} style={{maxWidth: '570px', margin: 'auto'}}>
            <Box style={{display: 'flex', flexDirection: 'row'}}>
              <Box style={{display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'left'}}>
                <Typography sx={{fontWeight: 'bold'}}>{practice.name}</Typography>
                <Typography>{practice.address}</Typography>
              </Box>
              <>
                {practicesWithErrors.includes(practice.id) && <ErrorOutlineIcon style={{margin: 'auto', color: 'red'}} />}
                <Button onClick={() => setEditingPractice(practice)}>
                  EDIT
                </Button>
              </>
            </Box>
            <Divider variant='middle' style={{marginBottom: '10px', marginTop: '10px'}}/>
          </Box>
        )})}
      </>
    )

    return (
        <div>
            <CoveredAppBar />
            <Box sx={{ display: 'flex' }}>
              <Drawer
                variant="permanent"
                sx={{
                  width: drawerOpen ? 240 : 60,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: drawerOpen ? 240 : 60, boxSizing: 'border-box', height: '100%', maxHeight: '100vh', overflow: 'clip' },
                }}
              >
                <Toolbar />
                <Box sx={{ minWidth: '20px', height: 'auto', marginTop: '2em' }}>
                  <List sx={{marginTop: '10px'}}>
                    {sections.map((text, index) => (
                      <ListItem key={text} disablePadding onClick={() => { setSelectedSection(text); navigate(`/user-profile/${text.toLowerCase().replace(' ','-')}`) }} selected={selectedSection === text}>
                        <ListItemButton sx={!drawerOpen && {margin: '10px 0 10px 0'}}>
                          <ListItemIcon>
                              {text === sections[0] && <MonetizationOnIcon />}
                              {text === sections[1] && <AccountBoxIcon />}
                              {text === sections[2] && <NotificationsIcon />}
                              {text === sections[3] && <CalendarMonthIcon />}
                              {text === sections[4] && <AddBusinessIcon />}
                              {text === sections[5] && <GroupsIcon />}
                          </ListItemIcon>
                          <ListItemText primary={drawerOpen ? text : null}/>
                          { text === sections[1] && profileBasicsError && <ErrorOutlineIcon style={{color: 'red'}} /> }
                          { text === sections[2] && isReliefDoc && reliefAvailabilityErrors() && <ErrorOutlineIcon style={{color: 'red'}} /> }
                          { text === sections[3] && !!practicesWithErrors.length && <ErrorOutlineIcon style={{color: 'red'}} /> }
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  <List sx={{position: 'absolute', bottom: '0', left: '0'}}>
                    <ListItem onClick={() => setDrawerOpen(!drawerOpen)} key='openclose' disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          {drawerOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
              <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: '95vw' }} textAlign='center'>
                {selectedSection === sections[0] && (
                  <Box sx={{maxHeight: '80vh'}}>
                    <h2>Account</h2>
                    {connectionCredits ?? 0} connection credit{connectionCredits === 1 ? '' : 's'} remaining
                    <Alert variant="outlined" severity="info" style={{margin: '20px'}}>
                      Connection credits are used to send connection requests. When you use a credit to send a request, the credit becomes unavailable while that connection is pending. If the request recipient accepts the connection, the credit will be spent. If the request is denied, the credit is returned to your account.
                    </Alert>
                    <h3>Add more credits</h3>
                    <Button onClick={() => { setNumCreditsToPurchase(1); setPurchaseCreditsAmount(3900); setPaymentDialogOpen(true) }} disabled={loading} variant='outlined' style={{textAlign: 'left', width: '40%', maxWidth: '320px', minWidth: '220px', marginBottom: '10px', textTransform: 'none'}}>
                      <Typography sx={{flexGrow: 1}}>Buy 1 credit</Typography>
                      <Typography>$39</Typography>
                    </Button>
                    <br />
                    <Button onClick={() => { setNumCreditsToPurchase(3); setPurchaseCreditsAmount(9900); setPaymentDialogOpen(true) }} disabled={loading} variant='outlined' style={{textAlign: 'left', width: '40%', maxWidth: '320px', minWidth: '220px', marginBottom: '10px', textTransform: 'none'}}>
                      <Typography sx={{flexGrow: 1}}>Buy 3 credits</Typography>
                      <strike style={{color: 'gray'}}>$117</strike>
                      <Typography sx={{marginLeft: '5px'}}>$99</Typography>
                    </Button>
                    <br />
                    <Button onClick={() => { setNumCreditsToPurchase(5); setPurchaseCreditsAmount(14900); setPaymentDialogOpen(true) }} disabled={loading} variant='outlined' style={{textAlign: 'left', width: '40%', maxWidth: '320px', minWidth: '220px', textTransform: 'none'}}>
                      <Typography sx={{flexGrow: 1}}>Buy 5 credits</Typography>
                      <strike style={{color: 'gray'}}>$195</strike>
                      <Typography sx={{marginLeft: '5px'}}>$149</Typography>
                    </Button>
                    <PaymentDialog userId={user?.id} open={paymentDialogOpen} close={() => setPaymentDialogOpen(false)} amount={purchaseCreditsAmount} numCredits={numCreditsToPurchase} />
                  </Box>
                )}
                {selectedSection === sections[1] && (
                  <Box sx={{maxHeight: '80vh'}}>
                    <h2>Basic Info</h2>
                    <Avatar src={profilePhotoUrl} sx={{margin: 'auto', height: 100, width: 100, marginTop: 1}}/>
                    <br />
                    <Button variant='outlined' component='label' style={{margin: '10px 0 15px 0'}}>
                      Change Photo
                      <input hidden type='file' accept="image/*" onChange={(e) => {
                        const newProfilePhoto = e.target.files[0];
                        uploadProfilePhoto(user.id, newProfilePhoto)
                        .then(newProfilePhotoUrl => {
                          setProfilePhotoUrl(newProfilePhotoUrl);
                          updateProfilePhoto(newProfilePhotoUrl);

                          // TODO: make user's profile photo update without reloading the page
                          window.location.reload();
                        })
                      }} />
                    </Button>
                    <br />
                    <TextField
                      id="email"
                      disabled
                      value={user?.email || ''}
                      type="text"
                      label="E-mail"
                      sx={{paddingBottom: '20px', width: '350px', maxWidth: '70vw', margin: '7px'}}
                      InputLabelProps={{shrink: !!user?.email}}
                    />
                    <br />
                    <TextField
                      id="name"
                      required
                      value={name}
                      error={errorValues.includes(name)}
                      onChange={(e) => { setName(e.target.value); }}
                      type="text"
                      label="Name"
                      sx={{paddingBottom: '20px', maxWidth: '350px', margin: '7px'}}
                      InputLabelProps={{shrink: !!name}}
                    />
                    <TextField
                      id="phoneNumber"
                      required
                      value={phoneNumber}
                      error={errorValues.includes(phoneNumber)}
                      onChange={(e) => { handlePhoneNumber(e, phoneNumber, setPhoneNumber) }}
                      type="text"
                      label="Mobile phone Number"
                      sx={{paddingBottom: '20px', margin: '7px', width: '200px'}}
                      InputLabelProps={{shrink: !!phoneNumber}}
                    />
                    <br/>
                    <Box textAlign='center'>
                        <Button variant='contained' onClick={() => { profileBasicsErrorsPresent() ? setProfileBasicsError(true) : updateProfile() }} style={{marginTop: '10px'}} disabled={savingProfile}>
                          { profileSaved ? <CheckIcon /> : 'Save' }
                        </Button>
                    </Box>
                  </Box>
                )}
                {selectedSection === sections[2] && (
                  <Box sx={{maxHeight: '80vh'}}>
                    <h2>Notification Preferences</h2>
                    <Typography>How would you like to be notified regarding your connection and job requests?</Typography>
                    <FormControlLabel
                      value="textOptIn"
                      control={<Checkbox checked={textOptIn} onChange={(e) => {setTextOptIn(e.target.checked)}}/>}
                      label="Text"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="emailOptIn"
                      control={<Checkbox checked={emailOptIn} onChange={(e) => {setEmailOptIn(e.target.checked)}}/>}
                      label="E-mail"
                      labelPlacement="end"
                    />
                    <Box textAlign='center'>
                        <Button variant='contained' onClick={() => { updateProfile() }} style={{marginTop: '10px'}} disabled={savingProfile}>
                          { profileSaved ? <CheckIcon /> : 'Save' }
                        </Button>
                    </Box>
                  </Box>
                )}
                {selectedSection === sections[3] && (
                  <>
                    <h2>Relief Availability</h2>
                    <FormControlLabel
                      value="isReliefDoc"
                      control={<Checkbox checked={isReliefDoc || false} onChange={(e) => {setIsReliefDoc(e.target.checked)}}/>}
                      label="I am available for relief work"
                      labelPlacement="end"
                      sx={{paddingBottom: '10px'}}
                    />
                    <br />
                    <TextField
                      id="yearsOfExperience"
                      required
                      value={yearsOfExperience}
                      disabled={isReliefDoc !== true}
                      error={isReliefDoc && errorValues.includes(yearsOfExperience)}
                      onChange={(e) => { setYearsOfExperience(e.target.value); }}
                      type="number"
                      label="Years of Experience"
                      sx={{paddingBottom: '20px', marginLeft: '20px', width: '200px'}}
                      InputLabelProps={{shrink: !!yearsOfExperience}}
                    />
                    <TextField
                      id="licenseNumber"
                      required
                      disabled={isReliefDoc !== true}
                      value={licenseNumber}
                      error={isReliefDoc && errorValues.includes(licenseNumber)}
                      onChange={(e) => { setLicenseNumber(e.target.value); }}
                      type="text"
                      label="License Number"
                      sx={{paddingBottom: '20px', marginLeft: '20px', width: '200px'}}
                      InputLabelProps={{shrink: !!licenseNumber}}
                    />
                    <h3>Location</h3>
                    <TextField
                      id="maxTravelDistanceMiles"
                      required
                      disabled={isReliefDoc !== true}
                      error={isReliefDoc && errorValues.includes(maxTravelDistanceMiles)}
                      value={maxTravelDistanceMiles}
                      onChange={(e) => { setMaxTravelDistanceMiles(e.target.value); }}
                      type="number"
                      label="Max Travel Distance (Miles)"
                      sx={{paddingBottom: '20px', width: '200px'}}
                      InputLabelProps={{shrink: !!maxTravelDistanceMiles}}
                    />
                    <TextField
                      id="centralAddress"
                      required
                      disabled={isReliefDoc !== true}
                      error={isReliefDoc && errorValues.includes(centralAddress)}
                      defaultValue={centralAddress}
                      type="text"
                      label="Central Address"
                      sx={{paddingBottom: '20px', marginLeft: '20px', width: '350px'}}
                      InputLabelProps={{shrink: !!centralAddress}}
                    />
                    <h3>Dates</h3>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{width: '250px', display: 'flex', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px'}}>
                          <Typography gutterBottom sx={{fontWeight: 'bold'}}>
                            Anytime
                          </Typography>
                          <FormControlLabel
                            value="isClinic"
                            control={<Checkbox checked={datesFlexible || false} disabled={isReliefDoc !== true} onChange={(e) => {setDatesFlexible(e.target.checked)}}/>}
                            label="Available anytime/flexible"
                            labelPlacement="end"
                          />
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{paddingLeft: '30px', paddingRight: '30px', width: '250px'}}>
                          <Typography gutterBottom sx={{fontWeight: 'bold'}}>
                            Recurring
                          </Typography>
                          <FormControlLabel
                            value="availableForReliefRecurring"
                            control={<Checkbox checked={availableForReliefRecurring || false} disabled={isReliefDoc !== true} onChange={(e) => {console.log(e.target.checked); setAvailableForReliefRecurring(e.target.checked)}}/>}
                            label="Available for recurring relief"
                            labelPlacement="end"
                          />
                          <ToggleButtonGroup
                            value={reliefDocEstimatedReliefDaysOfWeek}
                            onChange={(_, newSelections) => { setReliefDocEstimatedReliefDaysOfWeek(newSelections) }}
                            aria-label="days of week"
                            style={{paddingBottom: '10px'}}
                            disabled={!isReliefDoc || !availableForReliefRecurring}
                          >
                            <ToggleButton value="U" aria-label="sunday">
                              Su
                            </ToggleButton>
                            <ToggleButton value="M" aria-label="monday">
                              M
                            </ToggleButton>
                            <ToggleButton value="T" aria-label="tuesday">
                              T
                            </ToggleButton>
                            <ToggleButton value="W" aria-label="wednesday">
                              W
                            </ToggleButton>
                            <ToggleButton value="R" aria-label="thursday">
                              R
                            </ToggleButton>
                            <ToggleButton value="F" aria-label="friday">
                              F
                            </ToggleButton>
                            <ToggleButton value="A" aria-label="saturday">
                              Sa
                            </ToggleButton>
                          </ToggleButtonGroup>
                          Start: <DatePicker value={recurringAvailabilityStartDate} onChange={setRecurringAvailabilityStartDate} disabled={!isReliefDoc || !availableForReliefRecurring} style={{marginLeft: '10px', marginBottom: '10px'}}/>
                          <br />
                          End: <DatePicker value={recurringAvailabilityEndDate} onChange={setRecurringAvailabilityEndDate} disabled={!isReliefDoc || !availableForReliefRecurring} style={{marginLeft: '10px'}}/>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{display: 'flex', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px'}}>
                          <Typography gutterBottom sx={{fontWeight: 'bold'}}>
                            Specific Dates
                          </Typography>
                          <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Calendar
                                multiple
                                disabled={isReliefDoc !== true}
                                value={availability}
                                onChange={handleUpdateAvailability}
                                sx={{width: '250px'}}
                            />
                          </Box>
                        </Box>
                    </Box>
                    <h3>Pricing</h3>
                    <TextField
                      id="dailyRate"
                      required
                      disabled={isReliefDoc !== true}
                      error={isReliefDoc && errorValues.includes(dailyRate)}
                      value={dailyRate}
                      onChange={(e) => { setDailyRate(e.target.value); }}
                      type="number" label="Typical Daily Rate (USD)" sx={{paddingBottom: '20px', width: '275px'}}
                      InputLabelProps={{shrink: !!dailyRate}}
                    />
                    <TextField
                      id="dailyRateWeekendHoliday"
                      required
                      disabled={isReliefDoc !== true}
                      error={isReliefDoc && errorValues.includes(dailyRateWeekendHoliday)}
                      value={dailyRateWeekendHoliday}
                      onChange={(e) => { setDailyRateWeekendHoliday(e.target.value); }}
                      type="number"
                      label="Typical Weekend/Holiday Rate (USD)"
                      sx={{paddingBottom: '20px', marginLeft: '20px', width: '275px'}}
                      InputLabelProps={{shrink: !!dailyRateWeekendHoliday}}
                    />
                    <h3>Skills/Preferences</h3>
                    <TextField
                      id="maxPatientsPerHour"
                      required
                      disabled={isReliefDoc !== true}
                      error={isReliefDoc && errorValues.includes(maxPatientsPerHour)}
                      value={maxPatientsPerHour}
                      onChange={(e) => { setMaxPatientsPerHour(e.target.value); }}
                      type="number" label="Max Patients/Hour" sx={{paddingBottom: '20px', width: '275px'}}
                      InputLabelProps={{shrink: !!maxPatientsPerHour}}
                    />
                    <Box textAlign='center'>
                        <Button variant='contained' onClick={() => { !reliefAvailabilityErrors() && updateProfile() }} style={{marginTop: '10px'}} disabled={savingProfile || (isReliefDoc !== true)}>
                          { profileSaved ? <CheckIcon /> : 'Save' }
                        </Button>
                    </Box>
                  </>
                )}
                {selectedSection === sections[4] && (
                  <>
                    <h2>My Clinics</h2>
                    { !(creatingNewPractice || editingPractice) && userPractices }
                    { !(creatingNewPractice || editingPractice) && (
                      <div>
                          <Button variant="outlined" disabled={isClinic !== true} startIcon={<AddIcon />} onClick={() => setCreatingNewPractice(true)} style={{marginTop: '10px'}}>
                            Add a practice
                          </Button>
                      </div>
                    )}
                    { (creatingNewPractice || editingPractice) && (
                      <CreateOrEditPractice
                        editingPractice={editingPractice}
                        onSuccess={() => {
                          console.log('success')
                          setEditingPractice(null);
                          setCreatingNewPractice(null);
                          refreshPracticeData();
                        }}
                        onCancel={() => {
                          setEditingPractice(null);
                          setCreatingNewPractice(null);
                        }}
                      />
                    )}
                  </>
                )}
                {selectedSection === sections[5] && (
                  <>
                    <Typography variant='h6' color="text.primary" gutterBottom>
                      My Connections {addingKnownUsers && <Typography sx={{display: 'inline'}} variant='h6' color="text.secondary">{' / Add Users'}</Typography>}
                    </Typography>
                    {addingKnownUsers && (
                      <>
                        <Button onClick={() => setAddingKnownUsers(false)} sx={{float: 'left'}}>{'< BACK'}</Button>
                        <br />
                        <br />
                        <Typography sx={{paddingBottom: '15px'}} color="text.secondary" gutterBottom>
                          Get connected with users you already know by entering their cell phone number.
                          There is no cost to you for connecting with users you already know.
                        </Typography>
                        <TextField
                          error={false}
                          id="connectionPhoneNumber"
                          value={connectionPhoneNumber}
                          onChange={(e) => {handlePhoneNumber(e, connectionPhoneNumber, setConnectionPhoneNumber)}}
                          type="text"
                          label="Mobile phone number"
                          helperText="ex. 111-222-3456"
                          sx={{ paddingBottom: '20px'}} />
                        <LoadingButton variant='contained' sx={{marginLeft:'20px', lineHeight: '43px'}} loading={searchingForExistingConnection} onClick={() => {
                          if (addedConnections.map(connection => connection.phoneNumber).includes(connectionPhoneNumber)) {
                            setConnectionPhoneNumber('');
                            return
                          }
                          setSearchingForExistingConnection(true)
                          fetch(new URL(`api/users/?phone_number=${connectionPhoneNumber}`, process.env.REACT_APP_BASE_URL), {
                            headers: {
                              'Authorization': 'Bearer ' + token,
                            }
                          })
                              .then(response => response.json())
                              .then(responseJson => {
                                if (responseJson.detail === 'User not found') {
                                  setSearchingForExistingConnection(false)
                                  setAddedConnections([{phoneNumber: connectionPhoneNumber, success: false, failureReason: responseJson.detail}, ...addedConnections]);
                                  setConnectionPhoneNumber('');
                                  return
                                }
                                fetch(new URL(`api/connection-requests/`, process.env.REACT_APP_BASE_URL), {
                                  method: 'POST',
                                  headers: {
                                      'accept': 'application/json',
                                      'Content-Type': 'application/json',
                                      'Authorization': 'Bearer ' + token,
                                  },
                                  body: JSON.stringify({
                                    ...auth,
                                    request: {
                                      status: 'pending',
                                      cost: 0,
                                      travel_distance: 0,
                                      preexisting_connection: true,
                                      requester_id: user.id,
                                      requestee_id: responseJson[0].id,
                                    }
                                  })
                                })
                                .then((response) => response.json())
                                .then(responseJson => {
                                  setSearchingForExistingConnection(false)
                                  setConnectionPhoneNumber('');

                                  if (responseJson.detail === 'Already connected to user') {
                                    setAddedConnections([{phoneNumber: connectionPhoneNumber, success: false, failureReason: responseJson.detail}, ...addedConnections]);
                                    return
                                  }
                                  setAddedConnections([{phoneNumber: connectionPhoneNumber, success: true}, ...addedConnections]);
                                })
                              })
                        }}>Add</LoadingButton>
                        <div>
                          { addedConnections.map((connection) => (
                            <div style={{textAlign: 'initial', width: '300px', margin: 'auto', paddingLeft: '30px'}}>
                              {connection.success && <CheckCircleOutlineOutlinedIcon sx={{verticalAlign: '-15px', color: 'green'}}/>}
                              {!connection.success && <CancelOutlinedIcon sx={{verticalAlign: '-15px', color: 'red'}} />}
                              <div style={{display: 'inline-grid', marginLeft: '10px'}}>
                                {connection.success && <Typography>Connection request sent</Typography>}
                                {!connection.success && <Typography>{connection.failureReason}</Typography>}
                                <Typography sx={{fontSize: 14, textAlign: 'initial'}} color="text.secondary" gutterBottom>{connection.phoneNumber}</Typography>
                              </div>
                              {connection.failureReason === 'User not found' && <Button disabled={sentInvites.includes(connection.phoneNumber)} onClick={() => { sendUserInvite(connection.phoneNumber) }} variant='outlined' style={{marginLeft: '10px', verticalAlign: '-10px'}}>Invite</Button>}
                            </div>
                          )) }
                        </div>
                        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={successfulInviteAlertDisplayed} autoHideDuration={4000} onClose={() => {setSuccessfulInviteAlertDisplayed(false)}}>
                          <Alert onClose={() => {setSuccessfulInviteAlertDisplayed(false)}} severity="success" sx={{ width: '100%' }}>
                            Invite sent!
                          </Alert>
                        </Snackbar>
                      </>

                    )}
                    {!addingKnownUsers && (
                      <>
                        <Button variant='outlined' onClick={() => setAddingKnownUsers(true)} sx={{marginBottom: '15px'}}>Add Users</Button>
                        <br/>
                        <ConnectionsList userId={user?.id} />
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
        </div>
    )
}
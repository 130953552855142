import './App.css';
import React, { useContext } from 'react';
import CoveredAppBar from './components/CoveredAppBar';
import Avatar from '@mui/material/Avatar';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Button from '@mui/material/Button';
import { Card, CardActions, CardContent, Divider } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { Checkbox } from '@mui/material';
import UserContext from './UserContext';
import ConnectionsList from './components/ConnectionsList';

function App(props) {
  const [selectedSignUpOrInTab, setSelectedSignUpOrInTab] = React.useState(props.signIn ? 1 : 0);
  const [contactDialogOpen, setContactDialogOpen] = React.useState(false);

  const navigate = useNavigate();
  var { user, approvedUsers } = useContext(UserContext);

  const userApproved = approvedUsers?.includes(user?.id) || approvedUsers?.includes('all');

  if (user && !userApproved && process.env.REACT_APP_BASE_URL === 'https://my.getcovered.health/') {
    navigate('/almost-done', {replace: true})
  }

  const cards = (
    <Grid container spacing={2} sx={{paddingTop: '10px', justifyContent: 'center'}}>
      <Grid item>
        <Card sx={{ minWidth: '380px', width: '32vw', height: '70vh', display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ maxHeight: '68%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography variant='h5' sx={{ float: 'left', width: '100%', display: 'block' }} gutterBottom>
              Connections
            </Typography>
            <>
              &infin; credits remaining
              <br/>
              <br/>
            </>
            <div style={{overflow: 'auto'}}>
              <ConnectionsList userId={user?.id} />
            </div>
          </CardContent>
          <CardActions style={{display: 'flex', flexDirection: 'column', margin: 'auto', marginBottom: '20px'}}>
            {/* <Button variant='outlined' size="large" sx={{margin: 'auto'}} onClick={() => navigate('/user-profile/connections/add-users')}>Add Known User</Button> */}
            <Button variant='contained' size="large" sx={{margin: 'auto', marginTop: '20px'}} onClick={() => navigate('/search')}>Search for new connection</Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item>
        <Card sx={{ minWidth: '380px', width: '32vw', height: '70vh' }}>
          <CardContent sx={{ height: '80%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h5' sx={{ float: 'left', width: '100%' }} gutterBottom>
              My Profile Summary
            </Typography>
            <div>
              <Avatar src={user?.profile_photo_url} sx={{margin: 'auto', height: 100, width: 100, marginTop: 1, marginBottom: 1}}/>
              {user?.name}
              <br/>
              <br/>
              <Checkbox checked={user?.is_clinic} disabled/> I am looking for relief doctors
              <br/>
              <Checkbox checked={user?.is_relief_doc} disabled/> I am available for relief
            </div>
          </CardContent>
          <CardActions>
            <Button variant='outlined' onClick={() => navigate('/user-profile/basics')} size="large" sx={{margin: 'auto'}}>View/Edit Profile</Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item>
        <Card sx={{ minWidth: '380px', maxWidth: '32vw', height: '70vh', display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{flexGrow: 1, maxHeight: '80%'}}>
            <Typography variant='h5' sx={{ float: 'left', width: '100%' }} gutterBottom>
              Latest News
            </Typography>
            <div style={{textAlign: 'left', paddingLeft: '10px'}}>
              <Divider />
              <Typography sx={{ fontSize: 14, paddingLeft: '5px' }} color="text.secondary">
                May 2, 2023
              </Typography>
              <Typography variant="h6" sx={{paddingLeft: '5px'}}>
                New Feature: Add Known Contacts
              </Typography>
              <Typography sx={{fontSize: 14, paddingLeft: '5px'}}>
                You can now add users without using a connection credit by entering their cell phone number, to prevent accidentally paying for a connection you already know.
              </Typography>
              <Button size="small" onClick={() => window.open("https://getcovered.health/updates/new-feature-add-known-contacts", "_blank")}>Learn More</Button>
              <Divider />
            </div>
          </CardContent>
          <CardActions>
            <Button variant='outlined' size="large" sx={{margin: 'auto'}} onClick={() => setContactDialogOpen(true)}>Contact Support</Button>
            <Dialog
              open={contactDialogOpen}
              onClose={() => setContactDialogOpen(false)}
              aria-labelledby="contact-info"
              aria-describedby="cfh@getcovered.health"
            >
              <DialogTitle id="contact-info">
                {"Contact Info"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please e-mail cfh@getcovered.health for support. Thank you!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setContactDialogOpen(false)}>Close</Button>
              </DialogActions>
            </Dialog>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )

  const signInOrUpCard = (
    <Card sx={{ width: '600px' }}>
      <Tabs value={selectedSignUpOrInTab} onChange={(_, newVal) => setSelectedSignUpOrInTab(newVal)} aria-label="basic tabs example">
        <Tab label="Sign Up" />
        <Tab label="Log In" />
      </Tabs>
      {selectedSignUpOrInTab === 0 && (
        <SignUp />
      )}
      {selectedSignUpOrInTab === 1 && (
        <SignIn />
      )}
    </Card>
  )

  return (
    <div className="App">
      <CoveredAppBar />
      <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '20px'}}>
      { user && cards }
      { !user && signInOrUpCard }
      </div>
    </div>
  );
}

export default App;

import * as React from 'react';
import { Button, CardContent, TextField } from '@mui/material';
import { CardActions } from '@mui/material';
import { Link } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import UserContext from '../UserContext';

export default function SignIn() {
    let { returnPath } = useParams();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorText, setErrorText] = React.useState('');

    const navigate = useNavigate();
    const { setUser } = React.useContext(UserContext);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const signIn = () => {
        if (email === '') {
            setErrorText('Email is required.')
            return
        }
        if (password === '') {
            setErrorText('Password is required.')
            return
        }

        fetch(new URL('api/login', process.env.REACT_APP_BASE_URL), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            username: email,
            password: password
          })
        })
        .then(response => response.json())
        .then((data) => {
            console.log(data)
            if (data.user) {
                localStorage.setItem("token", data.token)
                localStorage.setItem("userId", JSON.stringify(data.user.id))
                localStorage.setItem("userEmail", JSON.stringify(data.user.email))
                setUser(data.user)

                if (returnPath) {
                    navigate(decodeURIComponent(returnPath), {replace: true})
                } else {
                    navigate('/', {replace: true});
                }
            } else {
                data.detail && setErrorText(data.detail)
                !data.detail && setErrorText('There was a problem. Please contact support at cfh@getcovered.health')
            }
        })
    };

    const signInIfEnterKeyPressed = (event) => {
        if (event.key === 'Enter') {
            signIn()
        }
    }

    return (
        <>
            <CardContent>
                <TextField id="email" value={email} onChange={handleEmailChange} type="email" label="E-mail" sx={{width: '70%', paddingBottom: '20px'}}/>
                <TextField id="password" value={password} onChange={handlePasswordChange} onKeyDown={signInIfEnterKeyPressed} type="password" label="Password" sx={{width: '70%', paddingBottom: '20px'}}/>
                { errorText && (
                  <div style={{color: 'red'}}>{errorText}</div>
                )}
                <div>
                    <Link href='/forgot-password'>Forgot Password?</Link>
                </div>
            </CardContent>
            <CardActions>
                <Button size="medium" variant='contained' sx={{margin: 'auto', marginBottom: '20px', bgcolor: '#5ab8c2'}} onClick={signIn}>Sign In</Button>
            </CardActions>
        </>
    )
}
import AWS from 'aws-sdk';

var bucketName = 'covered-photos';
var bucketRegion = 'us-east-2';
var identityPoolId = 'us-east-2:f2190cb1-4f07-44f0-84a0-16e3fc4c8ba1';

export function listObjects() {
    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId
        })
      });
      
    var s3 = new AWS.S3({
      params: { Bucket: bucketName }
    });

    s3.listObjects({ Delimiter: "/" }, (err, data) => {
      console.log(err)
      console.log(data)
      console.log(this.request.httpRequest.endpoint.href)

      var bucketUrl = 'https://covered-photos.s3.us-east-2.amazonaws.com/'

      var photoUrl = bucketUrl + encodeURIComponent(data.Contents[0].Key);

      return photoUrl
    })
}

export function uploadProfilePhoto(userId, photo) {
    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId
        })
      });

    const profilePhotoKey = userId + '_profile_photo';

    const upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: bucketName,
            Key: profilePhotoKey,
            Body: photo
        }
    });

    var promise = upload.promise()

    return promise.then(
        function(data) {
            console.log(data)

            return data.Location
        },
        function(error) {
            console.log(error)
        }
    )
}
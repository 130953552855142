import { Avatar, Button, Card, CardContent, Container, Typography } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import UserContext from '../UserContext';
import CoveredAppBar from './CoveredAppBar';

export default function RequestView() {
    let { id } = useParams();
    var { user } = React.useContext(UserContext);
    const token = localStorage.getItem("token");
    const [request, setRequest] = React.useState(null);
    const [city, setCity] = React.useState(null);

    const cityRegex =  /([a-zA-Z]+, [A-Z]{2}) \d/;

    const getRequest = () => {
        fetch(new URL(`api/connection-requests/${id}`, process.env.REACT_APP_BASE_URL), {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.detail) {
              console.log('oops :(')
              console.log(responseJson.detail)
            } else {
              setRequest(responseJson);

              let cityMatches = RegExp(cityRegex, 'g').exec(responseJson.practice?.address);
              if (cityMatches && cityMatches[1]) {
                setCity(cityMatches[1])
              }
            }
          })
    }

    const acceptRequest = () => {
      fetch(new URL(`api/connection-requests/${id}`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
            id: id,
            status: 'booked',
        })
      })
      .then((response) => response.json())
      .then((responseJson) => setRequest(responseJson))
    }

    const declineRequest = () => {
      fetch(new URL(`api/connection-requests/${id}`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
            id: id,
            status: 'declined',
        })
      })
      .then((response) => response.json())
      .then((responseJson) => setRequest(responseJson))
    }

    React.useEffect(() => {
        if (request === null) {
            getRequest()
        }
    })

    const RequesteeView = (props) => {
        return (
            <Container sx={{margin: 'auto', marginTop: '30px', marginBottom: '30px'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Avatar src={request?.requester?.profile_photo_url} sx={{marginRight: '50px', height: '100px', width: '100px'}}/>
                    <div sx={{flexDirection: 'column'}}>
                        { <Typography variant='h5'>Contact: {request.requester.name}</Typography> }
                        { !props.anonymous && <Typography variant='subtitle1'>Contact Email: {request.requester.email}</Typography> }
                        { !props.anonymous && <Typography variant='subtitle1'>Contact Phone Number: {request.requester.phone_number}</Typography> }
                        { request.practice && (
                            <>
                                <Typography variant='subtitle1'>Practice Name: {request.practice.name}</Typography>
                                { !props.anonymous && <Typography variant='subtitle1'>Practice Address: {request.practice.address}</Typography> }
                                { props.anonymous && <Typography variant='subtitle1'>Received {request?.date}</Typography> }
                            </>
                        ) }
                        <Chip label={request.status === 'booked' ? 'accepted' : request.status} color="primary" variant="outlined" size="small" />
                    </div>
                </div>
                <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '30px'}}>
                    { request.practice && (
                        <Card sx={{marginRight: '30px'}}>
                            <CardContent>
                                <Typography gutterBottom>Patient Demographics</Typography>
                                <li>
                                    Avg {request.practice.average_patients_per_hour}/hour
                                </li>
                                <li>
                                    Max {request.practice.maximum_patients_per_hour}/hour
                                </li>
                                <li>
                                    {request.practice.average_percent_pediatric}% pediatric
                                </li>
                                <li>
                                    {request.practice.average_percent_geriatric}% geriatric
                                </li>
                            </CardContent>
                        </Card>
                    ) }
                    { request.practice && (
                        <div>
                            <Typography variant='h6'>Practice Information</Typography>
                            { (!!request.travel_distance || request.travel_distance === 0) && city && (
                                <li>
                                    { city } ({request.travel_distance.toFixed(1)} miles away)
                                </li>) }
                            { (!request.travel_distance && request.travel_distance !== 0) && city && (
                                <li>
                                    { city }
                                </li>) }
                            <li>
                                Typically ${request.practice.weekday_rate} weekday/${request.practice.weekend_rate} weekend
                            </li>
                            { request.practice.emr && (
                                <li>
                                    EHR Software: {request.practice.emr}
                                </li>
                            ) }
                            <li>
                                {request.practice.scribe_available ? 'Scribe available' : 'Scribe not available'}
                            </li>
                            <li>
                                {request.practice.techs_do_prelims ? 'Techs do prelims' : 'Techs do not do prelims'}
                            </li>
                        </div>
                    ) }
                    { request.practice && (
                        <Card sx={{marginLeft: '30px'}}>
                            <CardContent>
                                <Typography gutterBottom>Hours</Typography>
                                {!request.practice.hours && <li>Unknown</li>}
                                { request.practice.hours && request.practice.hours.map(day => (
                                    <li>
                                        {day}
                                    </li>
                                ))}
                            </CardContent>
                        </Card>
                    ) }
                </Container>
                { request.status === 'pending' &&
                <>
                    <Alert variant="outlined" severity="info" style={{marginBottom: '20px'}}>
                        Accepting this connection does not commit you to any specific work or specific rate. Connections (at this point in time) are meant to serve as a basic passing of contact information - the details of when exactly relief work can/will be provided are to be worked out directly between the two parties after accepting a connection.
                    </Alert>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Button onClick={declineRequest} style={{width: '150px'}} variant='outlined'>Decline</Button>
                        <Button onClick={acceptRequest} style={{width: '150px'}} variant='contained'>Accept</Button>
                    </div>
                </>}
            </Container>
        )
    }

    const availabilityTypes = (docResult) => {
        let result = []
        if (docResult.available_for_relief_dates_flexible) {
          result.push('Open/flexible')
        }

        if (docResult.estimated_relief_days_of_week) {
          let weeklyRecurringDays = []

          if (docResult.estimated_relief_days_of_week.includes('M')) {
            weeklyRecurringDays.push('Monday')
          }
          if (docResult.estimated_relief_days_of_week.includes('T')) {
            weeklyRecurringDays.push('Tuesday')
          }
          if (docResult.estimated_relief_days_of_week.includes('W')) {
            weeklyRecurringDays.push('Wednesday')
          }
          if (docResult.estimated_relief_days_of_week.includes('R')) {
            weeklyRecurringDays.push('Thursday')
          }
          if (docResult.estimated_relief_days_of_week.includes('F')) {
            weeklyRecurringDays.push('Friday')
          }
          if (docResult.estimated_relief_days_of_week.includes('A')) {
            weeklyRecurringDays.push('Saturday')
          }
          if (docResult.estimated_relief_days_of_week.includes('U')) {
            weeklyRecurringDays.push('Sunday')
          }

          result.push('Weekly recurring on ' + weeklyRecurringDays.join(', '))
        }

        const futureSpecificDateAvailability = docResult.availability.filter(date => new Date(date) >= new Date())

        if (futureSpecificDateAvailability.length) {
          result.push('Specific dates: ' + futureSpecificDateAvailability.join(', '))
        }

        return result
      }

    const RequesterView = (props) => {
        return (
            <Container sx={{margin: 'auto', marginTop: '30px', marginBottom: '30px'}}>
                { request.status === 'booked' && (
                    <Alert variant="outlined" severity="success" style={{marginBottom: '20px'}}>
                        Congratulations! This request has been accepted, so the doctor's contact information is now visible. Your contact information has also been shared with this doctor.
                    </Alert>
                ) }
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Avatar src={request?.requestee?.profile_photo_url} sx={{marginRight: '50px', height: '100px', width: '100px', filter: (props.anonymous ? 'blur(18px)' : '')}}/>
                    <div sx={{flexDirection: 'column'}}>
                        { props.anonymous ? <Typography variant='h5'>Pending Connection Request</Typography> : <Typography variant='h5'>{request.requestee.name}</Typography> }
                        {/* { !props.anonymous && <Typography variant='subtitle1'>{request.practice.name}</Typography> } */}
                        { props.anonymous && <Typography variant='subtitle1'>Sent {request.date}</Typography> }
                        <Chip label={request.status === 'booked' ? 'accepted' : request.status} color="primary" variant="outlined" size="small" />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '30px'}}>
                        <div>
                            <Typography variant='h6'>Doctor Information</Typography>
                            <li>
                                { request.travel_distance.toFixed() } miles away (will travel {request.requestee.max_travel_distance_miles} miles)
                            </li>
                            <li>
                                { request.requestee.years_of_experience ?? 'Unknown' } years of experience
                            </li>
                            <li>
                                { request.requestee.max_patients_per_hour ?? 'Unknown'} max patients/hour
                            </li>
                            <li>
                                Availability (last updated {new Date(request.requestee.availability_last_updated).toLocaleDateString('en-us')}):
                                {availabilityTypes(request.requestee).map(availabilityType => (
                                  <li key={availabilityType} style={{paddingLeft: '10px'}}>{ availabilityType }</li>
                                ))}
                            </li>
                        </div>
                    </Container>
                    { request.status === 'booked' &&  <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '30px'}}>
                        <div>
                            <Typography variant='h6'>Contact Information</Typography>
                            <li>
                                Phone: {request.requestee.phone_number}
                            </li>
                            <li>
                                Email: {request.requestee.email}
                            </li>
                            <li>
                                License Number: {request.requestee.license_number}
                            </li>
                        </div>
                    </Container>}
                </div>
            </Container>
        )
    }

    return (
        <>
            <CoveredAppBar />
            { !!request && request?.requestee_id === user?.id && <RequesteeView anonymous={(request?.status !== 'booked') && !request?.preexisting_connection}/> }
            { !! request && request?.requester_id === user?.id && <RequesterView anonymous={(request?.status !== 'booked') && !request?.preexisting_connection}/> }
        </>
    )
}
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Divider, ListItem, Avatar, ListItemText, Chip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UserContext from '../UserContext';

export default function SignIn(props) {
    var { user } = React.useContext(UserContext);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const [connections, setConnections] = React.useState([]);
    const [connectionsLoaded, setConnectionsLoaded] = React.useState(false);

    if (!connectionsLoaded && user) {
        fetch(new URL(`api/connection-requests/?requester_id=${user.id}&requestee_id=${user.id}`, process.env.REACT_APP_BASE_URL), {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
            .then(response => response.json())
            .then(responseJson => {
              if (responseJson.detail) {
                console.log('oops :(')
                console.log(responseJson.detail)
              } else {
                setConnections(responseJson);
              }
              setConnectionsLoaded(true);
            })
    }

    let anonymize = (connectionRequest) => {
      if (connectionRequest?.status === 'booked') {
          return false
      }

      if (connectionRequest?.preexisting_connection) {
          return false
      }

      if (connectionRequest?.requestee_id === user.id) {
          return false
      }

      return true
    }

    let getOtherUserId = (request) => {
      return request.requester_id === user.id ? request.requestee_id : request.requester_id
    }

    return (
        <>
          {!connections?.length && <i>None (yet!)</i>}
          {connections?.sort((a, b) => (b.date > a.date) ? -1 : 0).map((request) =>
            <div key={request.id}>
              <Divider />
              <ListItem button onClick={() => navigate(`/user/${getOtherUserId(request)}`)}>
                <Avatar src={request.requester_id === user?.id ? request.requestee.profile_photo_url : request.requester.profile_photo_url} sx={anonymize(request) && {filter: 'blur(8px)'}} />
                { !anonymize(request) && <ListItemText sx={{marginLeft: '10px'}} primary={(request.requester_id === user?.id ? request.requestee.name : request.requester.name)} /> }
                { anonymize(request) && <ListItemText sx={{marginLeft: '10px'}} primary={'Pending Connection Request'} /> }
                { request.status === 'pending' && <Chip label='pending'/>}
                <div>
                  <ChevronRightIcon />
                </div>
              </ListItem>
            </div> 
          )}
        </>
    )
}
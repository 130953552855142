import * as React from 'react';
import { Button, CardContent, TextField } from '@mui/material';
import { CardActions } from '@mui/material';
import { useNavigate } from "react-router-dom";
import UserContext from '../UserContext'

export default function SignUp() {
    const navigate = useNavigate();

    const { setUser } = React.useContext(UserContext);

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const [errorText, setErrorText] = React.useState(null)

    const createAccount = () => {
        if (email === '') {
            setErrorText('Email is required.')
            return
        }

        if (!email.includes('@')) {
            setErrorText('Please check email format.')
            return
        }

        if (!email.includes('.')) {
            setErrorText('Please check email format.')
            return
        }

        if (password === '') {
            setErrorText('Password is required.')
            return
        }

        if (password && confirmPassword !== password) {
            setErrorText('Passwords do not match.')
            return
        }

        fetch(new URL('api/users', process.env.REACT_APP_BASE_URL), {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        })
        .then(response => response.json())
        .then((data) => {
            if (data.detail) {
                setErrorText(data.detail)
                return
            }

            console.log(data)
            if (data.user) {
                localStorage.setItem("token", data.token)
                localStorage.setItem("userId", JSON.stringify(data.user.id))
                localStorage.setItem("userEmail", JSON.stringify(data.user.email))

                setUser(data.user)

                navigate('/registration', {replace: true});
            } else {
                console.log(':(')
            }
        })
    };

    const submitIfEnter = (e) => {
        if (e.key === 'Enter') {
            createAccount()
        }
    }

    return (
        <>
            <CardContent>
                <TextField
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    label="E-mail"
                    sx={{ width: '70%', paddingBottom: '20px'}}
                />
                <TextField
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    label="Password"
                    sx={{ width: '70%', paddingBottom: '20px'}}
                />
                <TextField
                    id="confirmpassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={submitIfEnter}
                    type="password"
                    label="Confirm password"
                    sx={{ width: '70%', paddingBottom: '20px'}}
                />
                { errorText && (
                  <div style={{color: 'red'}}>{errorText}</div>
                )}
            </CardContent>
            <CardActions>
                <Button size="medium" variant='contained' sx={{margin: 'auto', marginBottom: '20px', bgcolor: '#5ab8c2'}} onClick={createAccount}>Create Account</Button>
            </CardActions>
        </>
    )
}
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import AlmostDone from './components/AlmostDone';
import ForgotPassword from './components/ForgotPassword';
import RegistrationFlow from './components/RegistrationFlow';
import Search from './components/Search';
import UserProfile from './components/UserProfile';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from './UserContext';
import RequestView from './components/RequestView';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserView from './components/UserView';

const container = document.getElementById('root');
const root = createRoot(container);


function RootElement () {
  const [user, setUser] = React.useState(null);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    if (token) {
      window.onfocus = () => {
        fetch(new URL(`api/check-token`, process.env.REACT_APP_BASE_URL), {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => {
          if ([401, 403].includes(response.status) && !(window.location.pathname.includes('/sign-') || window.location.pathname.includes('/forgot-password'))) {
            // Token is expired, redirect to sign in
            localStorage.setItem("token", null)
            localStorage.setItem("practice", null)
            localStorage.setItem("userId", null)
            localStorage.setItem("userEmail", null)

            setUser(null);
            window.location = `/sign-in/${encodeURIComponent(window.location.pathname)}`
          }
          console.log(response)
        })
      }
    }
  })

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5ab8c2',
        contrastText: '#fff',
      },
    },
  });

  if (!userId && !(window.location.pathname.includes('/sign-') || window.location.pathname.includes('/forgot-password'))) {
    window.location = `/sign-in/${encodeURIComponent(window.location.pathname)}`
  }

  if (userId && !user) {
    fetch(new URL(`api/users/${userId}`, process.env.REACT_APP_BASE_URL), {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      if ([401, 403].includes(response.status) && !(window.location.pathname.includes('/sign-in') || window.location.pathname.includes('/forgot-password'))) {
        // Token is expired, redirect to sign in
        localStorage.setItem("token", null)
        localStorage.setItem("practice", null)
        localStorage.setItem("userId", null)
        localStorage.setItem("userEmail", null)

        setUser(null);
        window.location = `/sign-in/${encodeURIComponent(window.location.pathname)}`
      } else {
        return response.json()
      }
    })
    .then(data => {
      if (data && !data.detail) {
        setUser(data)
      }
    })
  }

  return (
    <UserProvider value={{user: user, setUser: setUser, approvedUsers: [
      'all',
      '60f4785f-f07e-4a3f-ba5c-5fa8e9f8b0f9', // Test User
      'b4b3a22a-ade3-4a5a-8d74-1187f779b947', // Dr Simpson
      'f988718b-bdb9-45fa-bd00-7ec9eeec05a2', // Brandon
      '6a327e82-1f21-4bf2-a6fa-a06090bd9aac', // Dr Hughes
    ]}}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/sign-in/:returnPath" element={<App signIn/>} />
            <Route path="/sign-in" element={<App signIn/>} />
            <Route path="/sign-up" element={<App />} />
            <Route path="/almost-done" element={<AlmostDone />} />
            <Route path="/registration" element={<RegistrationFlow />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/:id" element={<ForgotPassword />} />

            <Route path="/request/:id" element={<RequestView />} />
            <Route path="/user/:id" element={<UserView />} />
            <Route path="/search" element={<Search />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/user-profile/account" element={<UserProfile selectedSection='Account' />} />
            <Route path="/user-profile/basics" element={<UserProfile selectedSection='Basics' />} />
            <Route path="/user-profile/notifications" element={<UserProfile selectedSection='Notifications' />} />
            <Route path="/user-profile/relief-availability" element={<UserProfile selectedSection='Relief Availability' />} />
            <Route path="/user-profile/my-clinics" element={<UserProfile selectedSection='My Clinics' />} />
            <Route path="/user-profile/connections" element={<UserProfile selectedSection='Connections' />} />
            <Route path="/user-profile/connections/add-users" element={<UserProfile selectedSection='Connections' selectedSubsection='known-users' />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </UserProvider>
  )
}

root.render(<RootElement />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material';
import CoveredAppBar from './CoveredAppBar';

export default function ForgotPassword() {
    const [email, setEmail] = React.useState('');
    const [requestCreated, setRequestedCreated] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    let { id } = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const createPasswordResetRequest = () => {
        if (email) {
            fetch(new URL(`api/forgot-password`, process.env.REACT_APP_BASE_URL), {
                method: 'POST',
                headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({
                    email: email
                })
              })
                .then(response => response.json())
                .then(responseJson => {
                  if (responseJson.detail) {
                    console.log('oops :(')
                    console.log(responseJson.detail)
                  } else {
                    setRequestedCreated(true);
                  }
                })
        }
    }

    const updatePassword = () => {
        if (id && newPassword) {
            fetch(new URL(`api/update-password`, process.env.REACT_APP_BASE_URL), {
                method: 'POST',
                headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({
                    request_id: id,
                    new: newPassword
                })
              })
                .then(response => response.json())
                .then(responseJson => {
                  if (responseJson.detail) {
                    console.log('oops :(')
                    console.log(responseJson.detail)
                  } else {
                      setSuccess(true);
                  }
                })
        }
    }

    // User has already created a forgotten password request and clicked the link from their email
    return (
        <>
        {id && !success && (
            <>
                <CoveredAppBar canNav={false}/>
                <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '20px'}}>
                    <Card sx={{ width: '600px' }}>
                            <>
                                <CardContent>
                                    <Typography>Please enter a new password.</Typography>
                                    <TextField id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" label="New password" sx={{width: '90%', margin: 'auto', marginTop: '20px'}}/>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={updatePassword} variant='contained' style={{margin: 'auto', marginBottom: '20px'}}>Submit</Button>
                                </CardActions>
                            </>
                    </Card>
                </div>
            </>
        )}
        {id && success && (
            <>
                <CoveredAppBar canNav={false}/>
                <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '20px'}}>
                    <Card sx={{ width: '600px' }}>
                            <>
                                <CardContent>
                                    <Typography>Your password was successfully reset! Please continue to the sign in page.</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => navigate('/sign-in')} variant='contained' style={{margin: 'auto', marginBottom: '20px'}}>Go to Sign In</Button>
                                </CardActions>
                            </>
                    </Card>
                </div>
            </>
        )}
        {!id && (
            <>
                <CoveredAppBar canNav={false}/>
                <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '20px'}}>
                    <Card sx={{ width: '600px' }}>
                        {!requestCreated && (
                            <>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Forgotten Password
                                    </Typography>
                                    <Typography>
                                        Please enter your email. A password reset link will be sent to your email address.
                                    </Typography>
                                    <TextField id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" label="E-mail" sx={{width: '90%', margin: 'auto', marginTop: '20px'}}/>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={createPasswordResetRequest} variant='contained' style={{margin: 'auto', marginBottom: '20px'}}>Submit</Button>
                                </CardActions>
                            </>
                        )}
                        {requestCreated && <Typography style={{padding: '20px'}}>Request submitted. Please click the link in your email to continue resetting your password. Thank you!</Typography>}
                    </Card>
                </div>
            </>
        )}
        </>
    )
}
import { Card, CardActions, CardContent, Checkbox, FormControlLabel, TextField } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import * as React from 'react';
import CoveredAppBar from './CoveredAppBar';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'react-multi-date-picker';
import { Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import UserContext from '../UserContext';
import { uploadProfilePhoto } from './S3Interfaces';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { handlePhoneNumber } from '../utils/utils';
import { Alert, FormLabel, RadioGroup, Radio } from '@mui/material';


const ClinicSelection = (props) => {
  React.useEffect(() => {
      var clinicAddressInput = document.getElementById('clinicSelection')
      var options = {
        componentRestrictions: { country: 'us' },
        fields: [
          'name',
          'formatted_address',
          'current_opening_hours',
          // 'rating',
        ]
      }

      props.callback(clinicAddressInput, options)
  })

  return (
    <TextField required error={props.clinicAddressError} id="clinicSelection" type="text" defaultValue={props.clinicSelection} label="Search for your clinic by name" sx={{ width: '75%', paddingBottom: '20px', marginLeft: '10px'}} />
  )
}

const ReliefDocCentralAddress = (props) => {
  React.useEffect(() => {
      var reliefDocAddressInput = document.getElementById('reliefDocAddress')
      var options = {
        componentRestrictions: { country: 'us' },
        fields: [
          'formatted_address',
        ]
      }

      props.callback(reliefDocAddressInput, options)
  })

  return (
    <TextField required error={props.error} id="reliefDocAddress" type="text" defaultValue={props.defaultValue} label="Central address" sx={{ width: '99%', paddingBottom: '20px'}} />
  )
}

export default function RegistrationFlow() {
    const { user, setUser } = React.useContext(UserContext);
    const token = localStorage.getItem("token");
    const practice = JSON.parse(localStorage.getItem("practice"));
    const auth = {auth: {token: token, email: user.email}};

    const navigate = useNavigate();

    // Common steps
    const [humanName, setHumanName] = React.useState(user.name ?? '');
    const [nameError, setNameError] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState(user.phone_number ?? '');
    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const [isClinic, setIsClinic] = React.useState(user.is_clinic);
    const [isReliefDoc, setIsReliefDoc] = React.useState(user.is_relief_doc);
    const [userTypeError, setUserTypeError] = React.useState(false);
    const [profilePhotoUrl, setProfilePhotoUrl] = React.useState(user.profile_photo_url ?? '');
    const [referralSource, setReferralSource] = React.useState(user.referral_source ?? 'linkedin');

    const [textOptIn, setTextOptIn] = React.useState(user.text_opt_in ?? false);
    const [emailOptIn, setEmailOptIn] = React.useState(user.email_opt_in ?? false);

    const [connectionPhoneNumber, setConnectionPhoneNumber] = React.useState('');
    const [searchingForExistingConnection, setSearchingForExistingConnection] = React.useState(false);
    const [addedConnections, setAddedConnections] = React.useState([]);

    // Clinic steps
    const [clinicSelection, setClinicSelection] = React.useState('');
    const [clinicName, setClinicName] = React.useState(practice?.name || '');
    const [clinicAddress, setClinicAddress] = React.useState(practice?.address ?? '');
    const [clinicAddressError, setClinicAddressError] = React.useState(false);
    const [clinicType, setClinicType] = React.useState('Corporate')
    const [clinicCurrentHours, setClinicCurrentHours] = React.useState('');
    const [emr, setEMR] = React.useState('');
    const [clinicAvgPatientsPerHour, setClinicAvgPatientsPerHour] = React.useState('');
    const [clinicAvgPatientsPerHourError, setClinicAvgPatientsPerHourError] = React.useState(false);
    const [clinicMaxPatientsPerHour, setClinicMaxPatientsPerHour] = React.useState('');
    const [clinicMaxPatientsPerHourError, setClinicMaxPatientsPerHourError] = React.useState(false);
    const [scribeAvailable, setScribeAvailable] = React.useState(false);
    const [techsDoPrelims, setTechsDoPrelims] = React.useState(false);
    const [clinicWeekdayRate, setClinicWeekdayRate] = React.useState('');
    const [clinicWeekdayRateError, setClinicWeekdayRateError] = React.useState(false);
    const [clinicWeekendRate, setClinicWeekendRate] = React.useState('');
    const [clinicWeekendRateError, setClinicWeekendRateError] = React.useState(false);
    const [clinicAveragePercentPediatric, setClinicAveragePercentPediatric] = React.useState('');
    const [clinicAveragePercentPediatricError, setClinicAveragePercentPediatricError] = React.useState(false);
    const [clinicAveragePercentGeriatric, setClinicAveragePercentGeriatric] = React.useState('');
    const [clinicAveragePercentGeriatricError, setClinicAveragePercentGeriatricError] = React.useState(false);
    const [weekdayLunchBreakDurationMinutes, setWeekdayLunchBreakDurationMinutes] = React.useState('');
    const [weekdayLunchBreakDurationMinutesError, setWeekdayLunchBreakDurationMinutesError] = React.useState(false);
    const [weekendLunchBreakDurationMinutes, setWeekendLunchBreakDurationMinutes] = React.useState('');
    const [weekendLunchBreakDurationMinutesError, setWeekendLunchBreakDurationMinutesError] = React.useState(false);


    const [estimatedReliefDaysPerMonth, setEstimatedReliefDaysPerMonth] = React.useState(practice?.estimated_relief_days_per_month ?? '');
    const [estimatedReliefDaysPerMonthError, setEstimatedReliefDaysPerMonthError] = React.useState(false);
    const [estimatedReliefDaysOfWeek, setEstimatedReliefDaysOfWeek] = React.useState(practice?.estimated_relief_days_of_week?.split('') ?? []);
    const [estimatedReliefDaysOfWeekError, setEstimatedReliefDaysOfWeekError] = React.useState(false);
    const [clinicNeedDates, setClinicNeedDates] = React.useState([]);

    // Relief provider steps
    const [reliefDocLicenseNumber, setReliefDocLicenseNumber] = React.useState(user.license_number ?? '');
    const [reliefDocLicenseNumberError, setReliefDocLicenseNumberError] = React.useState(false);
    const [reliefDocLicenseState, setReliefDocLicenseState] = React.useState(user.license_state ?? '');
    const [reliefDocLicenseStateError, setReliefDocLicenseStateError] = React.useState(false);
    const [reliefDocMaxTravelDistance, setReliefDocMaxTravelDistance] = React.useState(user.max_travel_distance_miles ?? '');
    const [reliefDocMaxTravelDistanceError, setReliefDocMaxTravelDistanceError] = React.useState(false);
    const [reliefDocCentralAddress, setReliefDocCentralAddress] = React.useState(user.central_address ?? '');
    const [reliefDocCentralAddressError, setReliefDocCentralAddressError] = React.useState(false);
    const [reliefDocYearsOfExperience, setReliefDocYearsOfExperience] = React.useState(user.years_of_experience ?? '');
    const [reliefDocYearsOfExperienceError, setReliefDocYearsOfExperienceError] = React.useState(false);

    const [reliefDocStandardDailyRate, setReliefDocStandardDailyRate] = React.useState(user.daily_rate || '');
    const [reliefDocStandardDailyRateError, setReliefDocStandardDailyRateError] = React.useState(false)
    const [reliefDocWeekendHolidayDailyRate, setReliefDocWeekendHolidayDailyRate] = React.useState(user.daily_rate_weekend_holiday || '');
    const [reliefDocWeekendHolidayDailyRateError, setReliefDocWeekendHolidayDailyRateError] = React.useState(false)

    const [reliefDocEstimatedReliefDaysPerMonth, setReliefDocEstimatedReliefDaysPerMonth] = React.useState(user.estimated_relief_days_per_month ?? '');
    const [reliefDocEstimatedReliefDaysPerMonthError, setReliefDocEstimatedReliefDaysPerMonthError] = React.useState(false);
    const [reliefDocEstimatedReliefDaysOfWeek, setReliefDocEstimatedReliefDaysOfWeek] = React.useState(user.estimated_relief_days_of_week?.split('') ?? []);
    const [reliefDocEstimatedReliefDaysOfWeekError, setReliefDocEstimatedReliefDaysOfWeekError] = React.useState(false);
    const [reliefDocEstimatedReliefDates, setReliefDocEstimatedReliefDates] = React.useState(user.estimated_relief_dates ?? [])

    // Other
    const [activeStep, setActiveStep] = React.useState(0);

    const clinicSelectCallback = (clinicSelectionInput, options) => {
      if (window.google) {
        var clinicSelectionAutocomplete = new window.google.maps.places.Autocomplete(clinicSelectionInput, options)
        clinicSelectionAutocomplete.addListener('place_changed', () => {
          const clinic = clinicSelectionAutocomplete.getPlace();
          setClinicSelection(clinic.name);
          setClinicName(clinic.name);
          setClinicAddress(clinic.formatted_address);
          clinic.current_opening_hours?.weekday_text && setClinicCurrentHours(clinic.current_opening_hours.weekday_text);
          setClinicAddressError(false);
        });
      }
    }

    const reliefDocAddressCallback = (reliefDocCentralAddressInput, options) => {
      if (window.google) {
        var reliefDocCentralAddressAutocomplete = new window.google.maps.places.Autocomplete(reliefDocCentralAddressInput, options)
        reliefDocCentralAddressAutocomplete.addListener('place_changed', () => {
          setReliefDocCentralAddress(reliefDocCentralAddressAutocomplete.getPlace().formatted_address);
          setReliefDocCentralAddressError(false);
        });
      }
    }

    const handleUpdateClinicNeedDates = (dates) => {
      const newDates = dates.map(date => date.format('YYYY-MM-DD'))
      setClinicNeedDates(newDates);

      fetch(new URL(`api/practices/${practice.id}`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
          ...auth,
          practice: {
            ...practice,
            estimated_relief_dates: newDates
          },
        })
      })
    }

    const handleUpdateReliefDocEstimatedReliefDates = (dates) => {
      const newDates = dates.map(date => date.format('YYYY-MM-DD'))
      setReliefDocEstimatedReliefDates(newDates);

      fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        body: JSON.stringify({
          ...auth,
          user: {
            ...user,
            availability: newDates
          }
        })
      })
    }

    const steps = [
      // Common steps
      {
        render: (
          <>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Tell us about yourself!
              </Typography>
              <TextField
                required
                error={nameError}
                id="humanName"
                value={humanName}
                onChange={(e) => { setHumanName(e.target.value); setNameError(false)}}
                type="text"
                label="Your name"
                sx={{ width: '70%', paddingBottom: '20px'}} />
              <TextField
                required
                error={phoneNumberError}
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => {handlePhoneNumber(e, phoneNumber, setPhoneNumber); setPhoneNumberError(false)}}
                type="text"
                label="Mobile phone number"
                helperText="ex. 111-222-3456"
                sx={{ width: '70%', paddingBottom: '20px'}} />
              <Typography>How would you like to be notified regarding your connection and job requests?</Typography>
              <FormControlLabel
                value="textOptIn"
                control={<Checkbox checked={textOptIn} onChange={(e) => {setTextOptIn(e.target.checked)}}/>}
                label="Text"
                labelPlacement="end"
              />
              <FormControlLabel
                value="emailOptIn"
                control={<Checkbox checked={emailOptIn} onChange={(e) => {setEmailOptIn(e.target.checked)}}/>}
                label="E-mail"
                labelPlacement="end"
              />
              <Typography>I am:</Typography>
              <FormControlLabel
                value="isReliefDoc"
                control={<Checkbox checked={isReliefDoc} onChange={(e) => {setIsReliefDoc(e.target.checked); setUserTypeError(false)}}/>}
                label="Available for fill-in work"
                labelPlacement="end"
              />
              <FormControlLabel
                value="isClinic"
                control={<Checkbox checked={isClinic} onChange={(e) => {setIsClinic(e.target.checked); setUserTypeError(false)}}/>}
                label="Looking for fill-in support"
                labelPlacement="end"
              />
              { userTypeError && (
                <div style={{color: 'red'}}>Please select at least one.</div>
              )}
              <br/>
              <FormControl sx={{width: '70%', marginTop: '15px'}}>
                <InputLabel id="referral-source-select-label">How did you hear about us?</InputLabel>
                <Select
                  required
                  labelId="referral-source-select-label"
                  id="referral-source-select"
                  value={referralSource}
                  label="Referral Source"
                  onChange={(e) => setReferralSource(e.target.value)}
                >
                  <MenuItem value={'linkedin'}>LinkedIn</MenuItem>
                  <MenuItem value={'postcard'}>Postcard</MenuItem>
                  <MenuItem value={'conference'}>In-person conference</MenuItem>
                  <MenuItem value={'word-of-mouth'}>Word of mouth</MenuItem>
                  <MenuItem value={'toa-newsletter'}>TOA Newsletter</MenuItem>
                  <MenuItem value={'ctos-newsletter'}>CTOS Newsletter</MenuItem>
                  <MenuItem value={'google'}>Google</MenuItem>
                  <MenuItem value={'facebook'}>Facebook</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Select>
              </FormControl>
          </>
        ),
        stepHandlerForward: () => {
          const formErrorsPresent = () => {
            let isError = false;

            if (humanName === '') {
              setNameError(true)
              isError = true
            }

            if (phoneNumber === '') {
              setPhoneNumberError(true)
              isError = true
            }
            if (phoneNumber.length !== 12) {
              console.log('not 12')
              setPhoneNumberError(true)
              isError = true
            }
            if (phoneNumber.replaceAll('-', '').length !== 10) {
              console.log('not 10 ')
              setPhoneNumberError(true)
              isError = true
            }
            if (isNaN(phoneNumber.replaceAll('-', ''))) {
              console.log('not all nums')
              setPhoneNumberError(true)
              isError = true
            }

            if (!isClinic && !isReliefDoc) {
              setUserTypeError(true)
              isError(true)
            }

            return isError
          }

          if (formErrorsPresent()) {
            return
          }

          fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
              method: 'POST',
              headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token,
                },
              body: JSON.stringify({
                  ...auth,
                  user: {
                    id: user.id,
                    email: user.email,
                    name: humanName,
                    phone_number: phoneNumber,
                    is_clinic: isClinic,
                    is_relief_doc: isReliefDoc,
                    referral_source: referralSource,
                    text_opt_in: textOptIn,
                    email_opt_in: emailOptIn,
                  }
              })
          })
          .then(response => response.json())
          .then(data => {
            if (data.detail) {
              console.log('oops error')
            } else {
              setUser(data)

              setActiveStep(1)
            }
          })                
        }
      },

      {
        render: (
            <>
                <Typography variant='h6' color="text.primary" gutterBottom>
                  Load Existing Connections
                </Typography>
                <Typography sx={{paddingBottom: '15px'}} color="text.secondary" gutterBottom>
                  Use this page to load in your existing network, no connection credits required. (You can always do this later from your User Profile.)
                </Typography>
                <TextField
                  error={false}
                  id="connectionPhoneNumber"
                  value={connectionPhoneNumber}
                  onChange={(e) => {handlePhoneNumber(e, connectionPhoneNumber, setConnectionPhoneNumber)}}
                  type="text"
                  label="Cell phone number"
                  helperText="ex. 111-222-3456"
                  sx={{ width: '70%', paddingBottom: '20px'}} />
                <LoadingButton variant='contained' sx={{marginLeft:'20px', lineHeight: '43px'}} loading={searchingForExistingConnection} onClick={() => {
                  if (addedConnections.map(connection => connection.phoneNumber).includes(connectionPhoneNumber)) {
                    setConnectionPhoneNumber('');
                    return
                  }
                  setSearchingForExistingConnection(true)
                  fetch(new URL(`api/users/?phone_number=${connectionPhoneNumber}`, process.env.REACT_APP_BASE_URL), {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                    }
                  })
                      .then(response => response.json())
                      .then(responseJson => {
                        if (responseJson.detail === 'User not found') {
                          setSearchingForExistingConnection(false)
                          setAddedConnections([{phoneNumber: connectionPhoneNumber, success: false}, ...addedConnections]);
                          setConnectionPhoneNumber('');
                          return
                        }
                        fetch(new URL(`api/connection-requests/`, process.env.REACT_APP_BASE_URL), {
                          method: 'POST',
                          headers: {
                              'accept': 'application/json',
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer ' + token,
                          },
                          body: JSON.stringify({
                            ...auth,
                            request: {
                              status: 'pending',
                              cost: 0,
                              travel_distance: 0,
                              preexisting_connection: true,
                              requester_id: user.id,
                              requestee_id: responseJson[0].id,
                            }
                          })
                        })
                        .then((data) => {
                          setSearchingForExistingConnection(false)
                          setAddedConnections([{phoneNumber: connectionPhoneNumber, success: true}, ...addedConnections]);
                          setConnectionPhoneNumber('');
                        })
                      })
                }}>Add</LoadingButton>
                <div>
                  { addedConnections.map((connection) => (
                    <div>
                      {connection.success && <CheckCircleOutlineOutlinedIcon sx={{verticalAlign: '-15px', color: 'green'}}/>}
                      {!connection.success && <CancelOutlinedIcon sx={{verticalAlign: '-15px', color: 'red'}} />}
                      <div style={{display: 'inline-grid', marginLeft: '10px'}}>
                        {connection.success && <Typography>Connection request sent</Typography>}
                        {!connection.success && <Typography>No user with phone number</Typography>}
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>{connection.phoneNumber}</Typography>
                      </div>
                    </div>
                  )) }
                </div>
            </>
        ),
        stepHandlerForward: () => {
          if (isClinic) {
            setActiveStep(2)
          } else {
            setActiveStep(6)
          }
        },
        stepHandlerBackward: () => {
          setActiveStep(0)
        },
      },

      // Clinic Steps
      {
          render: (
              <>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  My Clinics
                </Typography>
                <Alert severity='info' style={{margin: '0 0 10px 10px'}}>More practices can be added after registration from your user profile.</Alert>
                <ClinicSelection callback={clinicSelectCallback} clinicSelection={clinicSelection} clinicAddressError={clinicAddressError}/>
                <div>
                  <FormControl sx={{width: '275px', marginBottom: '20px', marginLeft: '10px'}}>
                    <InputLabel id="emr-select-label">EMR Software</InputLabel>
                    <Select
                      required
                      labelId="emr-select-label"
                      id="emr-select"
                      value={emr}
                      label="EMR Software"
                      onChange={(e) => setEMR(e.target.value)}
                    >
                      <MenuItem value={'Crystal'}>Crystal</MenuItem>
                      <MenuItem value={'Compulink'}>Compulink</MenuItem>
                      <MenuItem value={'OfficeMate'}>OfficeMate</MenuItem>
                      <MenuItem value={'MaximEyes'}>MaximEyes</MenuItem>
                      <MenuItem value={'iTrust'}>iTrust</MenuItem>
                      <MenuItem value={'Uprise'}>Uprise</MenuItem>
                      <MenuItem value={'eClinicalWorks'}>eClinicalWorks</MenuItem>
                      <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <FormControl style={{marginLeft: '10px'}}>
                  <FormLabel id="clinic-type-radio-buttons-label">Clinic Description</FormLabel>
                  <RadioGroup
                    aria-labelledby="clinic-type-radio-buttons-label"
                    value={clinicType}
                    onChange={(e) => setClinicType(e.target.value)}
                    name="clinic-type-radio-buttons-group"
                  >
                    <FormControlLabel value="Corporate" control={<Radio />} label="Corporate" />
                    <FormControlLabel value="Multiple Doctor Private Practice" control={<Radio />} label="Multiple Doctor Private Practice" />
                    <FormControlLabel value="Single Doctor Private Practice" control={<Radio />} label="Single Doctor Private Practice" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
                {/* <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div style={{paddingLeft: '20px'}}>
                    { clinicCurrentHours && <b>Hours:</b> }
                    { clinicCurrentHours && clinicCurrentHours.map(weekday => (
                      <div key={weekday}>
                        <li>{weekday}</li>
                      </div>
                    )) }
                  </div>
                </div> */}
              </>
          ),
          stepHandlerForward: () => {
            const formErrorsPresent = () => {
              let isError = false;

              if (clinicAddress === '') {
                setClinicAddressError(true)
                isError = true
              }

              return isError
            }

            if (formErrorsPresent()) {
              return
            }

            const practice = JSON.parse(localStorage.getItem("practice"));

            if (practice) {
              fetch(new URL(`api/practices/${practice.id}`, process.env.REACT_APP_BASE_URL), {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                  body: JSON.stringify({
                    ...auth,
                    practice: {
                      ...practice,
                      name: clinicName,
                      address: clinicAddress,
                      state: clinicAddress.split(' ')[clinicAddress.split(' ').length - 3],
                      type: clinicType,
                      emr: emr,
                      hours: !!clinicCurrentHours ? clinicCurrentHours : [],
                    }
                  })
              })
              .then(response => response.json())
              .then(responseJson => {
                localStorage.setItem('practice', JSON.stringify(responseJson))
                setActiveStep(3)
              })

            } else {
              fetch(new URL(`api/practices`, process.env.REACT_APP_BASE_URL), {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                  body: JSON.stringify({
                      ...auth,
                      practice: {
                        owner_id: user.id,
                        name: clinicName,
                        address: clinicAddress,
                        state: clinicAddress.split(' ')[clinicAddress.split(' ').length - 3],
                        type: clinicType,
                        emr: emr,
                        hours: !!clinicCurrentHours ? clinicCurrentHours : [],
                      }
                  })
              })
              .then(response => response.json())
              .then(responseJson => {
                if (responseJson.detail) {
                  console.log('oops error')
                } else {
                  localStorage.setItem('practice', JSON.stringify(responseJson))
                  setActiveStep(3)
                }
              })
            }
          },
          stepHandlerBackward: () => {
            setActiveStep(1)
          }
      },
      {
          render: (
              <>
                <Typography sx={{ fontSize: 14, paddingBottom: '20px' }} color="text.secondary" gutterBottom>
                  Clinic Information (Continued)
                </Typography>
                <div>
                  <FormControl style={{width: '275px', paddingRight: '10px', paddingBottom: '20px'}}>
                    <InputLabel id="avg-patients-per-hour">Average patients/hour *</InputLabel>
                    <Select
                      required
                      labelId="avg-patients-per-hour-label"
                      id="avg-patients-per-hour-select"
                      value={clinicAvgPatientsPerHour}
                      error={clinicAvgPatientsPerHourError}
                      label="Clinic Type"
                      onChange={(e) => {setClinicAvgPatientsPerHour(e.target.value)}}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl style={{width: '275px', paddingBottom: '20px'}}>
                    <InputLabel id="max-patients-per-hour">Max patients/hour *</InputLabel>
                    <Select
                      required
                      labelId="max-patients-per-hour-label"
                      id="max-patients-per-hour-select"
                      value={clinicMaxPatientsPerHour}
                      error={clinicMaxPatientsPerHourError}
                      label="Clinic Type"
                      onChange={(e) => {setClinicMaxPatientsPerHour(e.target.value)}}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                  <div>
                    <TextField
                      id="weekdayLunchBreakDurationMinutes"
                      required
                      defaultValue={weekdayLunchBreakDurationMinutes}
                      error={weekdayLunchBreakDurationMinutesError}
                      onChange={(e) => { setWeekdayLunchBreakDurationMinutes(e.target.value); }}
                      type="number"
                      label="Weekday lunch duration (minutes)"
                      sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
                    />
                    <TextField
                      id="weekendLunchBreakDurationMinutes"
                      required
                      defaultValue={weekendLunchBreakDurationMinutes}
                      error={weekendLunchBreakDurationMinutesError}
                      onChange={(e) => { setWeekendLunchBreakDurationMinutes(e.target.value); }}
                      type="number"
                      label="Weekend lunch duration (minutes)"
                      sx={{paddingBottom: '20px', width: '275px'}}
                    />
                  </div>
                  <div>
                    <TextField
                      id="averagePercentPediatric"
                      required
                      defaultValue={clinicAveragePercentPediatric}
                      error={clinicAveragePercentPediatricError}
                      onChange={(e) => { setClinicAveragePercentPediatric(e.target.value); }}
                      type="number"
                      label="Average Percent Pediatric"
                      sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
                    />
                    <TextField
                      id="averagePercentGeriatric"
                      required
                      defaultValue={clinicAveragePercentGeriatric}
                      error={clinicAveragePercentGeriatricError}
                      onChange={(e) => { setClinicAveragePercentGeriatric(e.target.value); }}
                      type="number"
                      label="Average Percent Geriatric"
                      sx={{paddingBottom: '20px', width: '275px'}}
                    />
                  </div>
                  <div>
                    <TextField
                      id="typicalWeekdayRate"
                      required
                      defaultValue={clinicWeekdayRate}
                      error={clinicWeekdayRateError}
                      onChange={(e) => { setClinicWeekdayRate(e.target.value); setClinicWeekdayRateError(false); }}
                      type="number"
                      label="Typical Weekday Rate"
                      sx={{paddingBottom: '20px', paddingRight: '10px', width: '275px'}}
                    />
                    <TextField
                      id="typicalWeekdayHolidayRate"
                      required
                      defaultValue={clinicWeekendRate}
                      error={clinicWeekendRateError}
                      onChange={(e) => { setClinicWeekendRate(e.target.value); setClinicWeekendRateError(false); }}
                      type="number"
                      label="Typical Weekend/Holiday Rate"
                      sx={{paddingBottom: '20px', width: '275px'}}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="scribeAvailable"
                      control={<Checkbox checked={scribeAvailable || false} onChange={(e) => {setScribeAvailable(e.target.checked)}}/>}
                      label="Scribe typically available"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="techsDoPrelims"
                      control={<Checkbox checked={techsDoPrelims || false} onChange={(e) => {setTechsDoPrelims(e.target.checked)}}/>}
                      label="Techs typically do prelims"
                      labelPlacement="end"
                    />
                  </div>
                </div>
              </>
          ),
          stepHandlerForward: () => {
            const formErrorsPresent = () => {
              let isError = false;

              if (clinicAvgPatientsPerHour === '') {
                setClinicAvgPatientsPerHourError(true);
                isError = true;
              }

              if (clinicMaxPatientsPerHour === '') {
                setClinicMaxPatientsPerHourError(true);
                isError = true;
              }

              if (weekdayLunchBreakDurationMinutes === '') {
                setWeekdayLunchBreakDurationMinutesError(true);
                isError = true;
              }

              if (weekendLunchBreakDurationMinutes === '') {
                setWeekendLunchBreakDurationMinutesError(true);
                isError = true;
              }

              if (clinicAveragePercentPediatric === '') {
                setClinicAveragePercentPediatricError(true);
                isError = true;
              }

              if (clinicAveragePercentGeriatric === '') {
                setClinicAveragePercentGeriatricError(true);
                isError = true;
              }

              return isError
            }

            if (formErrorsPresent()) {
              return
            }

            const practice = JSON.parse(localStorage.getItem("practice"));

            if (practice) {
              fetch(new URL(`api/practices/${practice.id}`, process.env.REACT_APP_BASE_URL), {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                  body: JSON.stringify({
                    ...auth,
                    practice: {
                      ...practice,
                      average_patients_per_hour: clinicAvgPatientsPerHour,
                      maximum_patients_per_hour: clinicMaxPatientsPerHour,
                      weekday_lunch_break_duration_minutes: weekdayLunchBreakDurationMinutes,
                      weekend_lunch_break_duration_minutes: weekendLunchBreakDurationMinutes,
                      average_percent_pediatric: clinicAveragePercentPediatric,
                      average_percent_geriatric: clinicAveragePercentGeriatric,
                      weekday_rate: clinicWeekdayRate,
                      weekend_rate: clinicWeekendRate,
                      scribe_available: scribeAvailable,
                      techs_do_prelims: techsDoPrelims,
                    }
                  })
              })
              .then(response => response.json())
              .then(data => {
                if (data.detail) {
                  console.log('oops :(')
                  console.log(data.detail);
                } else {
                  localStorage.setItem('practice', JSON.stringify(data))
                  setActiveStep(4)
                }
              })

            } else {
              // uh oh
            }
          },
          stepHandlerBackward: () => {
            setActiveStep(2)
          }
      },
      {
          render: (
              <>
                  <Typography sx={{ fontSize: 14, paddingBottom: '20px' }} color="text.secondary" gutterBottom>
                    Clinic Relief Needs
                  </Typography>
                  <Typography display="inline">My clinic usually needs a relief doctor </Typography>
                  <TextField
                    required
                    error={estimatedReliefDaysPerMonthError}
                    size='small'
                    value={estimatedReliefDaysPerMonth}
                    onChange={(e) => { setEstimatedReliefDaysPerMonth(e.target.value); setEstimatedReliefDaysPerMonthError(false) }}
                    sx={{width: '60px'}} />
                  <Typography display="inline"> days per month.</Typography>
                  <br />
                  <br />
                  <Typography>My clinic usually needs a relief doctor on these days of the week (select all that apply):</Typography>
                  <ToggleButtonGroup
                    value={estimatedReliefDaysOfWeek}
                    onChange={(_, newSelections) => { setEstimatedReliefDaysOfWeek(newSelections); setEstimatedReliefDaysOfWeekError(false)}}
                    aria-label="days of week"
                  >
                    <ToggleButton value="U" aria-label="sunday">
                      Su
                    </ToggleButton>
                    <ToggleButton value="M" aria-label="monday">
                      M
                    </ToggleButton>
                    <ToggleButton value="T" aria-label="tuesday">
                      T
                    </ToggleButton>
                    <ToggleButton value="W" aria-label="wednesday">
                      W
                    </ToggleButton>
                    <ToggleButton value="R" aria-label="thursday">
                      R
                    </ToggleButton>
                    <ToggleButton value="F" aria-label="friday">
                      F
                    </ToggleButton>
                    <ToggleButton value="A" aria-label="saturday">
                      Sa
                    </ToggleButton>
                  </ToggleButtonGroup>
                { estimatedReliefDaysOfWeekError && (
                  <div style={{color: 'red'}}>Please select at least one.</div>
                )}
              </>
          ),
          stepHandlerForward: () => {
            const formErrorsPresent = () => {
              let isError = false;

              if (estimatedReliefDaysPerMonth === '') {
                setEstimatedReliefDaysPerMonthError(true)
                isError = true
              }

              if (estimatedReliefDaysOfWeek.length === 0) {
                setEstimatedReliefDaysOfWeekError(true)
                isError = true
              }

              return isError
            }

            if (formErrorsPresent()) {
              return
            }

            const practice = JSON.parse(localStorage.getItem("practice"));

            fetch(new URL(`api/practices/${practice.id}`, process.env.REACT_APP_BASE_URL), {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                  },
                body: JSON.stringify({
                  ...auth,
                  practice: {
                    ...practice,
                    estimated_relief_days_per_month: estimatedReliefDaysPerMonth,
                    estimated_relief_days_of_week: estimatedReliefDaysOfWeek.join(''),
                  }
                })
            })
            .then(response => response.json())
            .then(responseJson => {
              localStorage.setItem('practice', JSON.stringify(responseJson))
              setActiveStep(5)
            })
          },
          stepHandlerBackward: () => {
            setActiveStep(3)
          }
      },

      {
        render: (
          <>
            <Typography sx={{ fontSize: 14, paddingBottom: '20px' }} color="text.secondary" gutterBottom>
              Anticipated Need
              <Tooltip
                title="Feel free to give us a heads up if you know specific days that you may need a relief doctor."
                sx={{ fontSize: 14 }}
                enterTouchDelay={0}>
                <IconButton sx={{height: '1px'}}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Calendar
                multiple
                value={clinicNeedDates}
                onChange={handleUpdateClinicNeedDates}
            />
          </>
        ),
        stepHandlerForward: () => {
          if (isReliefDoc) {
            setActiveStep(6)
          } else {
            navigate('/', {replace: true})
          }

        },
        stepHandlerBackward: () => {
          setActiveStep(4)
        }
      },

      // Relief provider steps
        {
            render: (
                <>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Relief Provider Info
                  </Typography>
                  <FormControl sx={{width: '30%', paddingBottom: '20px', paddingRight: '20px'}}>
                    <InputLabel id="license-state-select-label" error={reliefDocLicenseStateError}>License State *</InputLabel>
                    <Select
                      required
                      labelId="license-state-select-label"
                      id="license-state-select"
                      value={reliefDocLicenseState}
                      label="License State *"
                      error={reliefDocLicenseStateError}
                      onChange={(e) => {setReliefDocLicenseState(e.target.value); setReliefDocLicenseStateError(false)}}
                    >
                      <MenuItem value={'AL'}>Alabama</MenuItem>
                      <MenuItem value={'AK'}>Alaska</MenuItem>
                      <MenuItem value={'AZ'}>Arizona</MenuItem>
                      <MenuItem value={'AR'}>Arkansas</MenuItem>
                      <MenuItem value={'AS'}>American Samoa</MenuItem>
                      <MenuItem value={'CA'}>California</MenuItem>
                      <MenuItem value={'CO'}>Colorado</MenuItem>
                      <MenuItem value={'CT'}>Connecticut</MenuItem>
                      <MenuItem value={'DE'}>Delaware</MenuItem>
                      <MenuItem value={'DC'}>District of Columbia</MenuItem>
                      <MenuItem value={'FL'}>Florida</MenuItem>
                      <MenuItem value={'GA'}>Georgia</MenuItem>
                      <MenuItem value={'GU'}>Guam</MenuItem>
                      <MenuItem value={'HI'}>Hawaii</MenuItem>
                      <MenuItem value={'ID'}>Idaho</MenuItem>
                      <MenuItem value={'IL'}>Illinois</MenuItem>
                      <MenuItem value={'IN'}>Indiana</MenuItem>
                      <MenuItem value={'IA'}>Iowa</MenuItem>
                      <MenuItem value={'KS'}>Kansas</MenuItem>
                      <MenuItem value={'KY'}>Kentucky</MenuItem>
                      <MenuItem value={'LA'}>Louisiana</MenuItem>
                      <MenuItem value={'ME'}>Maine</MenuItem>
                      <MenuItem value={'MD'}>Maryland</MenuItem>
                      <MenuItem value={'MA'}>Massachusetts</MenuItem>
                      <MenuItem value={'MI'}>Michigan</MenuItem>
                      <MenuItem value={'MN'}>Minnesota</MenuItem>
                      <MenuItem value={'MS'}>Mississippi</MenuItem>
                      <MenuItem value={'MO'}>Missouri</MenuItem>
                      <MenuItem value={'MT'}>Montana</MenuItem>
                      <MenuItem value={'NE'}>Nebraska</MenuItem>
                      <MenuItem value={'NV'}>Nevada</MenuItem>
                      <MenuItem value={'NH'}>New Hampshire</MenuItem>
                      <MenuItem value={'NJ'}>New Jersey</MenuItem>
                      <MenuItem value={'NM'}>New Mexico</MenuItem>
                      <MenuItem value={'NY'}>New York</MenuItem>
                      <MenuItem value={'NC'}>North Carolina</MenuItem>
                      <MenuItem value={'ND'}>North Dakota</MenuItem>
                      <MenuItem value={'MP'}>Northern Mariana Islands</MenuItem>
                      <MenuItem value={'OH'}>Ohio</MenuItem>
                      <MenuItem value={'OK'}>Oklahoma</MenuItem>
                      <MenuItem value={'OR'}>Oregon</MenuItem>
                      <MenuItem value={'PA'}>Pennsylvania</MenuItem>
                      <MenuItem value={'PR'}>Puerto Rico</MenuItem>
                      <MenuItem value={'RI'}>Rhode Island</MenuItem>
                      <MenuItem value={'SC'}>South Carolina</MenuItem>
                      <MenuItem value={'SD'}>South Dakota</MenuItem>
                      <MenuItem value={'TN'}>Tennessee</MenuItem>
                      <MenuItem value={'TX'}>Texas</MenuItem>
                      <MenuItem value={'TT'}>Trust Territories</MenuItem>
                      <MenuItem value={'UT'}>Utah</MenuItem>
                      <MenuItem value={'VT'}>Vermont</MenuItem>
                      <MenuItem value={'VA'}>Virginia</MenuItem>
                      <MenuItem value={'VI'}>Virgin Islands</MenuItem>
                      <MenuItem value={'WA'}>Washington</MenuItem>
                      <MenuItem value={'WV'}>West Virginia</MenuItem>
                      <MenuItem value={'WI'}>Wisconsin</MenuItem>
                      <MenuItem value={'WY'}>Wyoming</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    label='License Number'
                    defaultValue={user.license_number}
                    value={reliefDocLicenseNumber}
                    error={reliefDocLicenseNumberError}
                    onChange={(e) => { setReliefDocLicenseNumber(e.target.value); setReliefDocLicenseNumberError(false) }}
                    sx={{width: '30%', minWidth: '150px', paddingBottom: '20px'}} />
                  <TextField
                    required
                    label='Years of Experience'
                    type='number'
                    defaultValue={user.years_of_experience}
                    value={reliefDocYearsOfExperience}
                    error={reliefDocYearsOfExperienceError}
                    onChange={(e) => { setReliefDocYearsOfExperience(e.target.value); setReliefDocYearsOfExperienceError(false) }}
                    sx={{width: '30%', minWidth: '185px', paddingBottom: '20px', marginLeft: '20px'}} />
                  <TextField
                    required
                    label='Max Travel Distance (Miles)'
                    type='number'
                    defaultValue={user.max_travel_distance_miles?.toString()}
                    value={reliefDocMaxTravelDistance}
                    error={reliefDocMaxTravelDistanceError}
                    onChange={(e) => { setReliefDocMaxTravelDistance(e.target.value); setReliefDocMaxTravelDistanceError(false) }}
                    sx={{width: '60%', paddingBottom: '20px'}} />
                  <ReliefDocCentralAddress callback={reliefDocAddressCallback} error={reliefDocCentralAddressError} defaultValue={reliefDocCentralAddress}/>
                </>
            ),
            stepHandlerForward: () => {
              const formErrorsPresent = () => {
                let isError = false;

                if (reliefDocLicenseNumber === '') {
                  setReliefDocLicenseNumberError(true)
                  isError = true
                }

                if (reliefDocLicenseState === '') {
                  setReliefDocLicenseStateError(true)
                  isError = true
                }

                if (reliefDocMaxTravelDistance === '') {
                  setReliefDocMaxTravelDistanceError(true)
                  isError = true
                }

                if (reliefDocCentralAddress === '') {
                  setReliefDocCentralAddressError(true)
                  isError = true
                }

                if (reliefDocYearsOfExperience === '') {
                  setReliefDocYearsOfExperienceError(true)
                  isError = true
                }

                return isError
              }

              if (formErrorsPresent()) {
                return
              }

              fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                  body: JSON.stringify({
                      ...auth,
                      user: {
                        id: user.id,
                        email: user.email,
                        name: humanName,
                        phone_number: phoneNumber,
                        is_clinic: isClinic,
                        is_relief_doc: isReliefDoc,
                        license_number: reliefDocLicenseNumber,
                        license_state: reliefDocLicenseState,
                        max_travel_distance_miles: reliefDocMaxTravelDistance,
                        central_address: reliefDocCentralAddress,
                        years_of_experience: reliefDocYearsOfExperience,
                      }
                  })
              })
              .then(response => response.json())
              .then(data => {
                if (data.detail) {
                  console.log('oops error')
                } else {
                  setUser(data)

                  setActiveStep(7)
                }
              })
            },
            stepHandlerBackward: () => {
              if(isClinic) {
                setActiveStep(5)
              } else {
                setActiveStep(2)
              }
            }
        },

        {
            render: (
                <>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Rates
                  </Typography>
                  <TextField
                    required
                    label='Standard Daily Rate (USD)'
                    type='number'
                    value={reliefDocStandardDailyRate}
                    error={reliefDocStandardDailyRateError}
                    onChange={(e) => {setReliefDocStandardDailyRate(e.target.value); setReliefDocStandardDailyRateError(false)}}
                    sx={{width: '60%', paddingBottom: '20px'}} />
                  <TextField
                    required
                    label='Weekend/Holiday Daily Rate (USD)'
                    type='number'
                    value={reliefDocWeekendHolidayDailyRate}
                    error={reliefDocWeekendHolidayDailyRateError}
                    onChange={(e) => {setReliefDocWeekendHolidayDailyRate(e.target.value); setReliefDocWeekendHolidayDailyRateError(false)}}
                    sx={{width: '60%', paddingBottom: '20px'}} />
                </>
            ),
            stepHandlerForward: () => {
              const formErrorsPresent = () => {
                let isError = false;

                if (reliefDocStandardDailyRate === '') {
                  setReliefDocStandardDailyRateError(true)
                  isError = true
                }

                if (reliefDocWeekendHolidayDailyRate === '') {
                  setReliefDocWeekendHolidayDailyRateError(true)
                  isError = true
                }

                return isError
              }

              if (formErrorsPresent()) {
                return
              }

              fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                  body: JSON.stringify({
                      ...auth,
                      user: {
                        id: user.id,
                        email: user.email,
                        daily_rate: reliefDocStandardDailyRate.replace('$', ''),
                        daily_rate_weekend_holiday: reliefDocWeekendHolidayDailyRate.replace('$', ''),
                      }
                  })
              })
              .then(response => response.json())
              .then(data => {
                if (data.detail) {
                  console.log('oops error')
                } else {
                  setUser(data)

                  setActiveStep(8)
                }
              })
            },
            stepHandlerBackward: () => {
              setActiveStep(6)
            }
        },


      {
          render: (
              <>
                  <Typography sx={{ fontSize: 14, paddingBottom: '20px' }} color="text.secondary" gutterBottom>
                    Recurring Availability
                  </Typography>
                  <Typography display="inline">I am typically available for relief services </Typography>
                  <TextField
                    required
                    value={reliefDocEstimatedReliefDaysPerMonth}
                    type='number'
                    error={reliefDocEstimatedReliefDaysPerMonthError}
                    size='small'
                    onChange={(e) => { setReliefDocEstimatedReliefDaysPerMonth(e.target.value); setReliefDocEstimatedReliefDaysPerMonthError(false) }}
                    sx={{width: '60px'}} />
                  <Typography display="inline"> days per month.</Typography>
                  <br />
                  <br />
                  <Typography>I am typically available for relief on these days of the week (select all that apply):</Typography>
                  <ToggleButtonGroup
                    value={reliefDocEstimatedReliefDaysOfWeek}
                    onChange={(_, newSelections) => { setReliefDocEstimatedReliefDaysOfWeek(newSelections); setReliefDocEstimatedReliefDaysOfWeekError(false) }}
                    aria-label="days of week"
                  >
                    <ToggleButton value="U" aria-label="sunday">
                      Su
                    </ToggleButton>
                    <ToggleButton value="M" aria-label="monday">
                      M
                    </ToggleButton>
                    <ToggleButton value="T" aria-label="tuesday">
                      T
                    </ToggleButton>
                    <ToggleButton value="W" aria-label="wednesday">
                      W
                    </ToggleButton>
                    <ToggleButton value="R" aria-label="thursday">
                      R
                    </ToggleButton>
                    <ToggleButton value="F" aria-label="friday">
                      F
                    </ToggleButton>
                    <ToggleButton value="A" aria-label="saturday">
                      Sa
                    </ToggleButton>
                  </ToggleButtonGroup>
                  { reliefDocEstimatedReliefDaysOfWeekError && (
                    <div style={{color: 'red'}}>Please select at least one.</div>
                  )}
              </>
          ),
          stepHandlerForward: () => {
              const formErrorsPresent = () => {
                let isError = false;

                if (reliefDocEstimatedReliefDaysPerMonth === '') {
                  setReliefDocEstimatedReliefDaysPerMonthError(true)
                  isError = true
                }

                if (reliefDocEstimatedReliefDaysOfWeek.length === 0) {
                  setReliefDocEstimatedReliefDaysOfWeekError(true)
                  isError = true
                }

                return isError
              }

              if (formErrorsPresent()) {
                return
              }

              fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                    },
                  body: JSON.stringify({
                      ...auth,
                      user: {
                        id: user.id,
                        email: user.email,
                        estimated_relief_days_per_month: reliefDocEstimatedReliefDaysPerMonth,
                        estimated_relief_days_of_week: reliefDocEstimatedReliefDaysOfWeek.join(''),
                      }
                  })
              })
              .then(response => response.json())
              .then(data => {
                if (data.detail) {
                  console.log('oops error')
                } else {
                  setUser(data)

                  setActiveStep(9)
                }
              })
          },
          stepHandlerBackward: () => {
            setActiveStep(7)
          }
      },

      {
        render: (
          <>
            <Typography sx={{ fontSize: 14, paddingBottom: '20px' }} color="text.secondary" gutterBottom>
              Specific Availability
            </Typography>
            <Typography sx={{paddingBottom: '20px'}}>
              This availability will be shown to clinics looking for help. <br />
              You can update it any time.
            </Typography>
            <Calendar
                multiple
                value={reliefDocEstimatedReliefDates}
                onChange={handleUpdateReliefDocEstimatedReliefDates}
            />
          </>
        ),
        stepHandlerForward: () => {
          setActiveStep(10)
        },
        stepHandlerBackward: () => {
          setActiveStep(8)
        }
      },

      {
        render: (
          <>
            <Typography sx={{ fontSize: 14, paddingBottom: '20px' }} color="text.secondary" gutterBottom>
              Add a Profile Photo
            </Typography>
            <Typography sx={{paddingBottom: '20px'}}>
              Clinics are more likely to reach out if your profile is complete with a photo.
            </Typography>
            <Avatar src={profilePhotoUrl} sx={{margin: 'auto', height: 100, width: 100, marginTop: 1}}/>
              <br />
              <Button variant='outlined' component='label' style={{display: 'flex'}}>
                Change Photo
                <input hidden type='file' accept="image/*" onChange={(e) => {
                  const newProfilePhoto = e.target.files[0];
                  uploadProfilePhoto(user.id, newProfilePhoto)
                  .then(newProfilePhotoUrl => {
                    console.log(newProfilePhotoUrl)
                    setProfilePhotoUrl(newProfilePhotoUrl);
                    const updatedProfile = {
                      id: user.id,
                      email: user.email,
                      profile_photo_url: newProfilePhotoUrl
                    }

                    fetch(new URL(`api/users/${user.id}`, process.env.REACT_APP_BASE_URL), {
                        method: 'POST',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                          },
                        body: JSON.stringify({
                          ...auth,
                          user: updatedProfile
                        })
                    })
                    .then(response => response.json())
                    .then(data => {
                      if (data.detail) {
                        console.log('oops')
                        console.log(data.detail)
                      } else {
                        setUser(data)
                      }
                    })
                  })
                }} />
              </Button>
          </>
        ),
        stepHandlerForward: () => {
          navigate('/', {replace: true})
        },
        stepHandlerBackward: () => {
          setActiveStep(9)
        }
      },
    ]

    return (
        <>
            <CoveredAppBar canNav={false}/>
            <div style={{display: 'flex', justifyContent: 'space-around', paddingTop: '20px'}}>
                <Card sx={{ width: '600px', minHeight: '485px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{flexGrow: 1}}>
                        {steps[activeStep]?.render}
                    </CardContent>
                    <CardActions>
                        <MobileStepper
                          variant="progress"
                          steps={steps.length}
                          position="static"
                          activeStep={activeStep}
                          sx={{ flexGrow: 1 }}
                          nextButton={
                            <Button size="small" onClick={steps[activeStep]?.stepHandlerForward}>
                              Next
                              <KeyboardArrowRight />
                            </Button>
                          }
                          backButton={
                            <Button size="small" onClick={() => {
                                setActiveStep(steps[activeStep]?.stepHandlerBackward)}
                              } disabled={activeStep === 0}>
                              <KeyboardArrowLeft />
                              Back
                            </Button>
                          }
                        />
                    </CardActions>
                </Card>
            </div>
        </>
    )
}
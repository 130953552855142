export function practiceErrorsPresent(practicesToCheck) {
  let practicesWithErrors = [];
  const errorValues = ['', null, undefined];

  practicesToCheck.forEach(practice => {
    if (errorValues.includes(practice.name)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.address)) {
      practicesWithErrors.push(practice.id)
    }

    // if (errorValues.includes(practice.phone_number)) {
    //   practicesWithErrors.push(practice.id)
    // }

    if (errorValues.includes(practice.weekday_rate)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.weekday_lunch_break_duration_minutes)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.weekend_rate)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.weekend_lunch_break_duration_minutes)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.average_patients_per_hour)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.maximum_patients_per_hour)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.average_percent_pediatric)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.average_percent_geriatric)) {
      practicesWithErrors.push(practice.id)
    }

    if (errorValues.includes(practice.emr)) {
      practicesWithErrors.push(practice.id)
    }
  })
  return practicesWithErrors;
}
export const handlePhoneNumber = (e, variableToSet, setterMethod) => {
   const input = e.target.value
   const userIsBackspacing = variableToSet.length > input.length;

   if (!userIsBackspacing && (input.length === 3 || input.length === 7)) {
     setterMethod(input + '-')
   } else if (!userIsBackspacing && (input.length === 5 || input.length === 9) && (input[input.length - 1] === '-')) {
     // If user enters a '-' know we already did that so don't include it
     return
   } else if (input.length === 13) {
     // Max number of characters
     return
   } else {
     setterMethod(input)
   }
}